import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import RegionBaseResHeader from "../../component/service/RegionBaseResHeader";
import InquirySection from "../../component/homepage/InquirySection";
import QuotesArea from "../../component/service/QuotesArea";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";
import {Link} from "react-router-dom";

const RegionBasedResearch = () => {
    const quoteTextList = [
        "정확한 의견을 듣기 위한 조사는 어떻게 만들죠?"
        , "많은 주민들이 참여했으면 좋겠어요."
        , "지역주민의 의견을 어떻게 분석하나요?"
    ]
    return (
        <>
            <SEO title="코나체인 | 지역기반의 공공 리서치"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>

                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/service-bg-3.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--210"
                    titleText = "지역기반의 공공 리서치"
                    subTitleText = "많은 지역주민의 목소리를 <br/>듣고 싶나요?"
                />

                <QuotesArea
                    bgClassName = "bg-color-light"
                    quotesTitle = "코나체인을 통해<br/>구성원의 목소리를 들어보세요."
                    quotesImageUrl = "/images/service/quotes_region-based-research.svg"
                />

                <RegionBaseResHeader/>

                <InquirySection/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default RegionBasedResearch;
