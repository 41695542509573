import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {blogApi} from "../../../api/blogApi";
import {DateUtil} from "../../../helpers/DateUtil";


const WidgetPost = ({categoryId}) => {


    const [searchParams, setSearchParams] = useState({
        pageSize : 3
        , pageNumber : 0
        , categoryId: categoryId
    });

    const [listInfo, setListInfo] = useState({
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
    });

    useEffect(() => {
        let _searchParams = searchParams;
        _searchParams.categoryId = categoryId;
        setSearchParams(_searchParams);
        if(categoryId){
            handleSearch();
        }

    }, [categoryId])


    const handleSearch = () => {
        console.log("calling handleSearch ... ", {searchParams});
        blogApi.getBlogList(searchParams)
            .then(res => {
                    setListInfo(prevState => {
                        return {
                            ...prevState,
                            ...res.data
                        }
                    })
                }
            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };


    return (
        <div className="post-list-wrap">

        {listInfo.content.map((data, index) => (
            <div className="single-post" key={index}>
                <div className="post-thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
                        <img src={`${data.titleImage}`} alt="Image"
                             onError={(e) => (e.target.src = "/images/blog/default_image.png")}
                        />
                    </Link>
                </div>
                <div className="post-content">
                    <h6 className="title">
                    <Link className="kc-p-margin-zero" to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} dangerouslySetInnerHTML={{__html: data.title}}>
                    </Link>
                    </h6>
                    <ul className="blog-meta list-unstyled">
                        <li>{DateUtil.format(data.postDate)}</li>
                        {/*<li>{data.read_time}</li>*/}
                    </ul>
                </div>
            </div>
        ))}
    </div>
    )
}

export default WidgetPost;


