import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SurveyPlatformProp from "../../component/product/SurveyPlatformProp";
import SurveyPanelData from "../../data/platform/SurveyPanelData.json";
import InquirySection from "../../component/homepage/InquirySection";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";

const surveyPanelData = SurveyPanelData;

const VotingPlatform = () => {

    return (
        <>
            <SEO title="코나체인 | 투표  플랫폼"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>

                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/voting-platform-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText="투표  플랫폼"
                    subTitleText="참여율 높은 투표 결과가 필요한가요?<br/><br/>누구나 쉽고 빠르게!<br/>그러나 익명성은 철저하게 보장되는<br/>플랫폼을 이용 해 보세요!"
                />

                <div className="section service-scroll-navigation-area kc-pt--90">
                    <div className="container">
                        <div className={`section-heading`}>
                            <h2 className="kc-title">간편한 투표 진행 절차</h2>
                            <p style={{width: "100%"}}>블록체인에 기반한 코나체인의 모바일 투표 시스템을 통해<br/>
                                안건 작성, 선거실시, 집계, 참여자 보상 제공까지 <span className="kc-highlight-yellow">일원화된 서비스를 간편하고 안전하게 이용</span>할
                                수 있습니다
                            </p>
                        </div>
                        <img src={process.env.PUBLIC_URL + "/images/platform/voting-platform-image-1.png"}
                             alt="bubble"/>
                    </div>
                </div>

                <div className="section service-scroll-navigation-area kc-pt--90 pb--90">
                    <div className="container">
                        <div className={`section-heading`}>
                            <h2 className="kc-title">업계최초 동형암호화 기술 적용</h2>
                            <p style={{width: "100%"}}>사용자의 투표 내용은 동형암호로 보호된 상태에서 집계되므로<br/>
                                <span className="kc-highlight-yellow">개인 프라이버시가 완벽하게 보호되어 익명성이 보장</span>됩니다</p>
                            <p style={{width: "100%"}}>코나아이와 크립토랩은 동형암호*를 적용해 투표의&nbsp;
                                <span className="kc-highlight-yellow">익명성</span>과 <span
                                    className="kc-highlight-yellow">기밀성</span>을 보장하는 투표/설문 서비스를 함께 개발했습니다
                                <br/>
                                투표 내용은 암호화되어 아무도 볼 수 없지만
                                <span className="kc-highlight-yellow">동형암호 기술</span>로 빠짐없이 집계할 수 있습니다
                            </p>
                        </div>

                        <div className="row row-45">
                            <div className="col-md-12">

                            </div>

                            <img src={process.env.PUBLIC_URL + "/images/platform/voting-platform-image-2.png"}/>
                            <img src={process.env.PUBLIC_URL + "/images/platform/voting-platform-image-3.png"}/>
                        </div>

                        <div className={`section-heading mt--80`}>
                            <h2 className="kc-title">블록체인 기술 적용</h2>
                            <p>사용자는 사용자 서명값을 통해 참여 행위의 부인을 방지하며, <br/>
                                <span className="kc-highlight-yellow">투표 결과는 위변조가 불가능하며, 투명성이 보장</span>됩니다.</p>
                        </div>
                        <div className="kc-center-div-p-90">
                            <img src={process.env.PUBLIC_URL + "/images/platform/voting-platform-image-4.png"}/>
                        </div>
                    </div>

                </div>

                <div className="section service-scroll-navigation-area bg-color-light kc-pt--90 pb--60">
                    <div className="container">
                        <div className="row">
                            <SurveyPlatformProp colSize="col-lg-2 col-md-6" serviceStyle="service-style-2"
                                                serviceData={surveyPanelData}/>
                        </div>
                    </div>
                </div>

                <InquirySection/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default VotingPlatform;
