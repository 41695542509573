import axios, {AxiosResponse} from "axios";

export const baseURL = process.env.REACT_APP_API_ROOT;
export const ResponseModel = AxiosResponse;
const baseOptions = {
	baseURL: baseURL,
	timeout: 0,
	withCredentials: false
};

export const api = axios.create(baseOptions);
export const asyncApi = axios.create(baseOptions);