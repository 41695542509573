/**
 *  @author Md. Abdullah Al Shafi
 *  @email abdullah.shafi@konasl.com
 *  @date 10/19/2022
 */
import {api} from "./";

export const inquiryApi = {
    inquirySubmit
    ,verifyRecaptcha
}

function inquirySubmit(payload) {
    return api.post('/api/contact/request', payload)
}

function verifyRecaptcha(payload) {
    return api.post('/api/auth/recaptcha/verify', payload)
}

