import React from 'react';
import {Link} from 'react-router-dom';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import FooterOne from "../common/footer/FooterOne";

const ErrorPage = () => {

    return (
        <>
        <SEO title="코나체인" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <div className="error-page onepage-screen-area">
                    <div className="container">
                        <div className="row align-items-center ">
                        <div className="col-lg-6">
                        <AnimationOnScroll  animateIn="slideInUp" duration={1} delay={300} animateOnce={true}>
                            <div className="content">
                                <h4>Page not found</h4>
                                <h5>페이지를 찾을 수 없습니다.</h5>
                                <p>페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.
                                    <br/>
                                    입력하신 주소가 정확한지 다시 한 번 확인해주세요.</p>
                                <Link to={process.env.PUBLIC_URL + "/"} className="axil-btn kc-axil-btn-2 btn-fill-primary btn-large">Home</Link>
                            </div>
                        </AnimationOnScroll>
                        </div>
                        <div className="col-lg-6">
                        <AnimationOnScroll  animateIn="zoomIn" duration={1} delay={300} animateOnce={true}>
                            <div className="thumbnail">
                                <img src={process.env.PUBLIC_URL + "/images/others/404.png"} alt="404" />
                            </div>
                        </AnimationOnScroll>
                        </div>
                        </div>
                    </div>

                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ErrorPage;
