import React from 'react';

export const CommonUtil = {
    getBrowserUrl
    , processDownloadUrl
    , getRowId
}

function getBrowserUrl() {
    let devUrl = process.env.REACT_APP_API_ROOT;
    // if (devUrl.startsWith("http")) {
    //     return devUrl;
    // }
    return window.location.protocol + "//" + window.location.host;
}

function processDownloadUrl(downloadUri) { //need to modify to fetch only baseUrl
    if (!downloadUri) //need to modify to fetch only baseUrl
        return downloadUri;
    let path = downloadUri;
    if (downloadUri.startsWith("http")) {
        path = new URL(downloadUri).pathname;
    }
    return this.getBrowserUrl() + path;
}

function getRowId(index, currentPageNumber, rowPerPage, totalElement = 0, order = 'ASC') {
    if (order === 'DESC') {
        // console.log({totalElement, index, rowPerPage, currentPageNumber})
        return (totalElement - (1 + index + rowPerPage * (currentPageNumber))) + 1;
    } else {
        return 1 + index + rowPerPage * (currentPageNumber);
    }
}