import React from 'react';
import {FaDotCircle} from "react-icons/fa";


const KonaCardDataTable = () => {
    return (
        <>
            <h4 className="kc-title-color-blue">코나카드 회원 Pool 현황</h4>
            <table className="table table-bordered" >
                <thead>
                <tr className="align-middle kc-bg-color-blue-text-white" align="center">
                    <th scope="col" style={{width: "33%"}}></th>
                    <th scope="col" style={{width: "34%"}}>KONACARD
                        <br/>패널 Pool 현황
                    </th>
                    <th scope="col" style={{width: "33%"}}>통계청
                        <br/>인구 추계</th>
                </tr>
                </thead>
                <tbody>
                <tr className="align-middle  table-primary" align="center">
                    <td>남성</td>
                    <td>62%</td>
                    <td>50%</td>
                </tr>
                <tr className="align-middle  table-primary" align="center">
                    <td>여성</td>
                    <td>38%</td>
                    <td>50%</td>
                </tr>
                <tr className="align-middle" align="center">
                    <td>15~19세</td>
                    <td>4%</td>
                    <td>6%</td>
                </tr>
                <tr className="align-middle" align="center">
                    <td>20~29세</td>
                    <td>16%</td>
                    <td>17%</td>
                </tr>
                <tr className="align-middle" align="center">
                    <td>30~39세</td>
                    <td>22%</td>
                    <td>17%</td>
                </tr>
                <tr className="align-middle" align="center">
                    <td>40~49세</td>
                    <td>30%</td>
                    <td>21%</td>
                </tr>
                <tr className="align-middle" align="center">
                    <td>50~59세</td>
                    <td>21%</td>
                    <td>22%</td>
                </tr>
                <tr className="align-middle" align="center" >
                    <td >60~69세</td>
                    <td >8%</td>
                    <td >18%</td>
                </tr>
                </tbody>
            </table>

        </>
    )
}

export default KonaCardDataTable;
