import React from 'react';
import { Link } from 'react-router-dom';


const Logo = ({limage, dimage, simage}) => {
    return (
        <Link to={process.env.PUBLIC_URL + "/"}>
            <img className="light-version-logo kc-logo-width_187" src={process.env.PUBLIC_URL + limage} alt="코나체인 - KONA CHAIN" width={187} height={22.5} />
            <img className="dark-version-logo kc-logo-width_187" src={process.env.PUBLIC_URL + dimage} alt="코나체인 - KONA CHAIN" width={187} height={22.5} />
            <img className="sticky-logo kc-logo-width_187" src={process.env.PUBLIC_URL + simage} alt="코나체인 - KONA CHAIN" width={187} height={22.5} />
        </Link>
    )
}


export default Logo;
