import React from 'react';
import LineDraw from "./LineDraw";
import PlatformPanelDataTable from "../../component/product/PlatformPanelDataTable";
import SectionTwoTableA from "./SectionTwoTableA";
import SectionTwoTableB from "./SectionTwoTableB";
import SectionTwoTableC from "./SectionTwoTableC";


const SectionTwo = () => {
    return (
        <>
            <section id="section-2">
                <h3 className="mt-0">2. 설문제작</h3>
            </section>

            <section id="section-2-1"><h4>2.1 설문제작 시작하기</h4>
                <p>설문은 연구하려고 해당하는 주제의 대상이나 목적, 주제에 대해 사람들의 인식, 생각 등을 파악하기 위한 조사 기법 중
                    <br/>하나입니다. 추후 나의 주제에 대한 근거가 되는 수단으로 사용됩니다.
                </p>
                <strong className="kc-title-color-blue">나의 설문을 체크해볼까요?</strong>
                <br/>
                <br/>
                <h6>체크리스트</h6>
                <ul>
                    <li>설문지 시작 단계에서 나의 연구 주제, 목표에 관해서 설명을 잘 해야 합니다.</li>
                    <li>분석하고자 하는 대상이 되는 응답자 정보를 질문해야 합니다.</li>
                    <li>설문의 흐름이 쉬운 난이도부터 어려운 난이도로 진행되게끔 구성이 되어야 합니다.</li>
                    <li>하나의 질문에서는 하나의 정보를 물어봐야 합니다.</li>
                    <li>설문지는 한쪽으로 편향되거나 치우치는 질문은 피해야 합니다.</li>
                    <li>개인의 사적인 정보나 사회적으로 민감한 질문은 피해야 합니다.</li>
                </ul>

                <p>
                    설문을 생성하기 전, 관리자에게 요청하여 설문제작 생성 권한을 획득해야 합니다.
                    <br/><strong className="kc-title-color-blue">설문조사 문의</strong>을 통해 요청 해 주세요.
                </p>
            </section>

            <section id="section-2-2"><h4>2.2 제목, 질문, 답변유형 설정</h4>
                <h6>설문 조사 제목</h6>
                <p>설문조사의 주제 및 제목을 선정합니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.2_1.png"} alt="설문제작"/>

                <h6>설문 조사 질문</h6>
                <p>알고자 하는 설문조사 질문을 입력합니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.2_2.png"} alt="설문제작"/>

                <h6>제목에 대한 설명 등록</h6>
                <p>설문조사 목적 또는 이해를 돕거나 추가적인 설명이 필요한 경우 내용을 텍스트 또는 이미지 형태로 등록할 수 있습니다.
                    <br/>또한 설명 위치도 설정 할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.2_3.png"} alt="설문제작"/>
                <ul>
                    <li>텍스트형으로는 추가적인 설명이 필요한 경우 글로써 내용을 전달할 수 있습니다.</li>
                    <li>이미지형으로는 사진 업로드를 통하여 내용을 전달할 수 있습니다.</li>
                </ul>

                <h6>설문 조사 질문</h6>
                <p>알고자 하는 설문조사 질문을 입력합니다.
                    <br/>답변 필수를 선택 할 시, 해당 질문은 반드시 응답해야 합니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.2_4.png"} alt="설문제작"/>

                <h6>질문에 대한 설명 등록</h6>
                <p>질문에 대한 이해를 돕거나 추가적인 설명이 필요한 경우 내용을 텍스트 또는 이미지 형태로 등록할 수 있습니다.
                    <br/>또한 설명의 위치도 설정할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.2_5.png"} alt="설문제작"/>
                <ul>
                    <li>텍스트형으로는 추가적인 설명이 필요한 경우 글로써 내용을 전달할 수 있습니다.</li>
                    <li>이미지형으로는 사진 업로드를 통하여 내용을 전달할 수 있습니다.</li>
                </ul>

                <h6>답변유형 설정</h6>
                <p>질문 응답의 목적에 따라 답변유형을 선택할 수 있습니다.
                    <br/>코나체인 설문조사 플랫폼에서는 선택형, 순위 선택형, 격자 질문/선택형, 서술형, 척도형을 지원합니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.2_6.png"} alt="설문제작"/>
            </section>

            <section id="section-2-3"><h4>2.3 선택형 설정</h4>
                <img src={process.env.PUBLIC_URL + "/images/document/2.3_1.png"} alt="선택형 설정"/>
                <p>일반적인 설문조사 답변 유형입니다. 하나의 답변만 선택할 수 있습니다.</p>

                <img src={process.env.PUBLIC_URL + "/images/document/2.3_2.png"} alt="선택형 설정"/>
                <p>복수선택 허용을 체크하면, 응답자는 여러 개의 답변을 선택할 수 있습니다.
                    <br/>생성자는 답변의 개수를 최소, 최대 값을 설정하여 조절할 수 있습니다.
                </p>

                <img src={process.env.PUBLIC_URL + "/images/document/2.3_3.png"} alt="선택형 설정"/>
                <p>그림과 같이 응답할 선택항목을 추가할 수 있습니다.
                    <br/>“선택 항목 한번에 편집하기”를 통해 쉽게 다수의 선택항목을 추가할 수 있습니다.
                </p>
            </section>

            <section id="section-2-4"><h4>2.4 순위형 설정</h4>
                <img src={process.env.PUBLIC_URL + "/images/document/2.4_1.png"} alt="순위형 설정"/>
                <p>복수 문항의 형태이지만 답변의 중요도에 따라서 1순위부터 최대 순위까지 선택할 수 있습니다.
                    <br/>생성자는 답변의 개수를 최소, 최대 값을 설정하여 조절할 수 있습니다.
                </p>
            </section>

            <section id="section-2-5"><h4>2.5 격자 질문/선택형 설정</h4>
                <img src={process.env.PUBLIC_URL + "/images/document/2.5_1.png"} alt="격자 질문/선택형 설정"/>
                <p>응답자가 동일한 열 보기를 통하여 여러 개 이상의 행 항목을 평가하기 위해 이 질문을 사용합니다.
                    <br/>아래의 질문과 같은 형태일 때, 이 유형을 사용하세요.
                </p>

                <p>Q1. 제시된 회사들에 대해서 귀하의 생각을 선택 해 주세요.</p>

                <SectionTwoTableA/>
                <br/>
                <br/>
                <img src={process.env.PUBLIC_URL + "/images/document/2.5_2.png"} alt="격자 질문/선택형 설정"/>
                <p>상위의 질문을 먼저 위와 같이 설정합니다.
                    <br/>그리고 아래와 같이 하위질문을 설정합니다.
                    <br/>만약 하위질문의 순서를 응답자에게 무작위 순서로 보여 줄 경우, “<strong className="kc-title-color-blue">하위 질문 순서 무작위로</strong>“를 선택하면 됩니다.
                    <br/>“하위 질문 한번에 편집하기“ 기능을 통해 다수의 항목을 한번에 등록할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.5_3.png"} alt="격자 질문/선택형 설정"/>

                <img src={process.env.PUBLIC_URL + "/images/document/2.5_4.png"} alt="격자 질문/선택형 설정"/>
                <p>응답자가 답변해야 할 선택항목을 위와 같이 추가합니다.</p>
            </section>

            <section id="section-2-6"><h4>2.6 척도형 설정</h4>
                <img src={process.env.PUBLIC_URL + "/images/document/2.6_1.png"} alt="척도형 설정"/>
                <p>리커트 척도의 형태로 3~11점까지의 척도를 나타내어 원하는 점수를 응답하게 합니다.
                    <br/>‘매우 그렇지 않다’ ~ ‘매우 그렇다‘ 와 같은 형태로 응답자들에게 점수와 함께 제시할 수 있습니다.
                    <br/>고객 만족도, NPS, 인지도 등에 관련된 문항을 질문할 때 사용합니다.
                </p>

                <p>Q1. A 제품에 대해서 귀하의 생각을 선택 해 주세요.</p>
                <SectionTwoTableB/>
                <br/>
                <p>위의 질문유형과 같이 격자형의 복수 척도형 질문을 생성할 경우,
                    <br/>아래와 같이 척도 유형 선택 시, “복수 질문＂을 선택하면 됩니다.
                    <br/>그 뒤의 하위질문 생성과정은 격자 질문/선택형과 동일하며, 선택항목 대신 척도 단계를 편집하면 됩니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.6_2.png"} alt="척도형 설정"/>
            </section>

            <section id="section-2-7"><h4>2.7 서술형 설정</h4>
                <p>직접 응답자의 생각을 주관식 형태로 응답 받을 수 있습니다.
                    <br/>서술형은 기본적으로 문자입력을 지원합니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.7_1.png"} alt="서술형 설정"/>

                <h6>숫자형식</h6>
                <p>예시 : Q. 식당의 만족도를 음식의 맛, 서비스, 가격으로 분류하여 총합이 100 되도록 평가하세요.
                    <br/>예시 질문과 같이 서술형 입력 값으로 숫자만 입력이 필요한 경우, 숫자형식으로 설정하면 됩니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.7_2.png"} alt="서술형 설정"/>

                <h6>답변 수 설정</h6>
                <p>예시 질문과 같이 각각 구분되어 작성이 필요한 경우, 아래와 같이 설정하여 필요한 답변 수를 설정할 수 있습니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.7_3.png"} alt="서술형 설정"/>

                <h6>단위 설정</h6>
                <p>유저에게 편의성 제공 및 입력 값을 명확하게 하기 위해 단위를 설정 할 수 있습니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.7_4.png"} alt="서술형 설정"/>

                <h6>답변 입력 범위 설정</h6>
                <p>예시 질문과 같이 의도하지 않은 범위의 입력값을 방지하기 위해 입력범위를 지정할 수 있습니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.7_5.png"} alt="서술형 설정"/>

                <h6>답변 총합량 설정</h6>
                <p>총합을 설정하여, 원하는 수치의 데이터를 수집할 수 있습니다.
                    <br/>또한 근사치를 추가 할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.7_6.png"} alt="서술형 설정"/>

                <h6>답변에 대한 설명 등록</h6>
                <p>각 입력값에 대하여 설명이 필요한 경우, 설명을 등록할 수 있습니다. 만약 설명을 설정하지 않는다면,
                    <br/>설명 없이 입력박스만 나타납니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.7_7.png"} alt="서술형 설정"/>

            </section>

            <section id="section-2-8"><h4>2.8 선택 항목 설정</h4>
                <p>선택형, 순위형, 격자 질문/선택형에서 선택 항목을 입력 후, 아래의 설정 값으로 추가 기능을 제공합니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.8_1.png"} alt="선택 항목 설정"/>

                <h6>없음 추가</h6>
                <p>없음 보기를 선택할 경우, 복수응답 또는 순위형에서 복수 응답이 아닌  다른 보기를 선택할 수 없게끔
                    <br/>해주는 기능을 추가할 수 있습니다.
                </p>

                <h6>기타 추가</h6>
                <p>원하는 보기가 없을 경우 '기타'를 추가하여 응답자가 스스로 기타 내용에 해당하는 응답을 할 수 있게 해줍니다.</p>

                <h6>선택 항목 순서 무작위</h6>
                <p>작성한 선택 항목를 응답자에게 무작위 순서로 보여줄 수 있도록 합니다.
                    <br/>만약 특정 선택 항목의 경우, 순서가 고정이 필요한 경우, <strong className="kc-title-color-blue">“순서 항상 고정“</strong>을 체크하여 고정할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.8_2.png"} alt="선택 항목 설정"/>

                <h6>선택 항목에 이미지 추가</h6>
                <p>이 설정을 체크하면 각 선택항목에 이미지를 추가 할 수 있습니다.
                    <br/>그리고 “기타”, “없음”의 선택항목에도 이미지를 추가 할 수 있습니다.
                    <br/>또한 모든 선택항목에 이미지를 추가할 필요없이 특정 보기만 이미지를 추가 할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.8_3.png"} alt="선택 항목 설정"/>

            </section>

            <section id="section-2-9"><h4>2.9 응답 후 옵션 설정</h4>
                <p>질문 응답 후, 다음 동작에 대해서 다양한 설정을 지원합니다.
                    <br/>설문 중간에 종료하거나 다음 질문이 아닌 다른 질문으로 건너 뛸 경우, 이 설정을 사용하면 됩니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.9_1.png"} alt="응답 후 옵션 설정"/>

                <h6>없음(다음질문으로 이동)</h6>
                <p>어떠한 설정도 필요없이 다음 질문으로 이동 할 경우, 이 옵션을 선택하면 됩니다.
                    <br/>기본값으로 이 값이 설정되어 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.9_1.png"} alt="응답 후 옵션 설정"/>

                <h6>답변에 따라 다른 질문으로 연결하기</h6>
                <p>답변에 따라 다음 질문이 아닌 다른 질문으로 연결 될 경우, 이 옵션을 통해 연결 할 질문을 설정하면 됩니다.
                    <br/>만약 다른 질문이 아닌 설문중단(종료)가 필요한 경우, 설문 종료를 선택합니다.
                    <br/>연결 질문으로 설정하지 않을 선택항목은 “0”을 입력해야 합니다.
                    <br/>설문 종료 시, 설문 조사 강제 종료 메시지를 설정할 수 있습니다.
                    <br/>
                    <br/>답변 유형이 선택형(단수) 유형일 경우,
                    <br/>설정이 가능합니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.9_3.png"} alt="응답 후 옵션 설정"/>

                <h6>답변 상관없이 특정 질문으로 연결하기</h6>
                <p>선택 항목에 따라 특정 질문으로 연결하는 것이 아닌 이 질문에 대해서 답변을 후,
                    <br/>특정 질문으로 연결이 필요할 때 사용할 수 있는 옵션입니다.
                    <br/>모든 답변 유형에서 설정할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.9_4.png"} alt="응답 후 옵션 설정"/>

                <h6>다중 선택 종료 설정</h6>
                <p>선택 복수형 또는 순위형에서 복수응답에 대해서 설문 종료(중단)이 필요한 경우, 이 옵션을 사용합니다.
                    <br/>설문 조사 종료 설정을 통해 종료 메시지를 설정 할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.9_5.png"} alt="응답 후 옵션 설정"/>

                <h6>설문 종료하기</h6>
                <p>선택 항목에 따라 설문 종료(중단)하는 것이 아니라 이 질문에 대해서 답변을 후,
                    <br/>설문 종료(중단)이 필요할 때 사용할 수 있는 옵션입니다.
                    <br/>모든 답변 유형에서 설정할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.9_6.png"} alt="응답 후 옵션 설정"/>

                <h6>질문진입조건 설정</h6>
                <p>이 설정을 통해 이 질문에 응답자가 진입할 수 있는 조건과 응답 후 어느 문항으로 이동할지에 대한 조건을 설정할 수 있습니다. 즉 해당 질문의 활성화 조건을 설정할 수 있습니다.
                    <br/>다만, 이 설정은 연산자로 구성된 명령어를 입력하는 방식으로 사용할 수 있습니다.
                    <br/>모든 답변 유형에서 설정할 수 있습니다.
                    <br/>그러나 질문1은 설정할 수 없습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.9_7.png"} alt="응답 후 옵션 설정"/>

                <p>사용가능한 연산자는 아래와 같습니다.</p>
                <SectionTwoTableC/>
                <p>
                    <br/>질문(Q)과 선택항목(A) 뒤에  설정하고 있는 질문에 진입해야 하는 응답 조건(질문 활성화 조건)을 만족하도록 작성합니다.
                    <br/>
                    <br/>질문 1에서 선택항목2를 선택한 응답자만 질문 3에 대해서 응답하기를 원하는 경우,
                    <br/>질문3의 진입 또는 활성화 조건 : Q1A2
                    <br/>여러 개의 복잡한 조건을 설정할 경우, 연산자를 조합하여 설정 할 수 있습니다.
                    <br/>
                    <br/>또한 대소문자를 구분하지 않으며, 소문자로 입력해도 상관없습니다.
                    <br/>질문과 선택항목 외에 연산자는 띄어쓰기를 정확하게 구분해야 정상적으로 동작합니다.
                    <br/>Q1A2 or Q2A2 (O)   / Q1A2orQ2A2 (X)
                </p>

                <h6>질문진입조건 예시</h6>
                <p className="mb-0">(1) 조건을 모두 충족해야만 해당 질문이 활성화되는 예시</p>
                <ul>
                    <li>Q5 : Q1A1 and Q2A1 and Q3A1</li>
                    <li>해석 : Q5가 활성화 되려면, Q1과 Q2, Q3의 선택항목을 모두 1번을 선택해야 활성화 및 진입할 수 있습니다.</li>
                </ul>
                <p className="mb-0">(2) 조건 중 하나만 충족해도 해당 질문이 활성화되는 예시</p>
                <ul>
                    <li>Q5 : Q1A1 or Q1A2 or Q3A1</li>
                    <li>해석 : Q5가 활성화 되려면, Q1의 선택항목 1 또는 2를 선택하거나
                        <br/>Q3의 선택항목 1을 선택하면, 활성화 됩니다.
                    </li>
                </ul>
                <p className="mb-0">(3) 특정 조건을 충족할 경우, 해당 질문이 비활성화되는 예시  중 하나만 충족해도 해당 질문이 활성화되는 예시</p>
                <ul>
                    <li>Q5 : Not Q2A1</li>
                    <li>해석 : Q2의 선택항목 1을 선택한 경우, Q5이 비활성화 됩니다.
                        <br/>Not조건에 해당하지 않는 모든 조건에는 Q5가  활성화 및 진입할 수 있습니다.
                    </li>
                </ul>
                <p className="mb-0">(4) 복잡한 진입(활성화)조건 예시</p>
                <ul>
                    <li>Q10 : Q3A2 or (Not (Q7A2 or Q7A3)) or (Q9A2 AND Q9A3)</li>
                    <li>해석 : Q3의 선택항목 2을 선택하거나
                        <br/>Q7의 선택항목 2 또는 3을 둘 중 하나라도 선택하지 않거나
                        <br/>Q9의 선택항목 2와 선택항목 3을 모두 선택했을 경우,
                        <br/>Q10의 질문이 활성화 되어 진입할 수 있습니다.
                    </li>
                </ul>
            </section>

            <section id="section-2-10"><h4>2.10 설문조사 등록 설정</h4>
                <p>설문조사의 질문과 선택항목 및 여러 설정을 완료했다면, 아래의 “설문조사 등록 설정하기”를 버튼을 선택하여 다음 페이지에서
                    생성된 설문조사의 “미리보기”, “설문조사 등록 설정하기”, “설문조사 참여기간 설정, 설문조사 참여자 지정,
                    참여 인원 제한, 설문조사 종료 메시지 등을 설정할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.10_1.png"} alt="설문조사 등록 설정"/>

                <h6>생성된 설문조사 미리보기</h6>
                <p>아래의 그림과 같이 생성된 설문조사의 내용을 확인 해 볼 수 있습니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.10_2.png"} alt="설문조사 등록 설정"/>

                <h6>설문조사 등록 설정하기 </h6>
                <p>진입질문 조건으로 설정된 질문은 활성화 조건을 충족했을 경우에만 질문이 노출되도록 설정 할 수 있습니다</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.10_3.png"} alt="설문조사 등록 설정"/>

                <p className="mb-0">진입질문 조건으로 설정된 질문은 활성화 조건을 충족했을 경우에만 질문이 노출되도록 설정 할 수 있습니다.</p>
                <ul>
                    <li>없음 : 모든 질문이 노출되고 진입질문 조건이 설정된 질문은 비활성화 상태로 표시되었다가 조건 충족 시, 활성화 됩니다.</li>
                    <li>숨김 후, 활성화 조건 충족 시 노출 : 해당 질문이 유저에게 표기되지 않다가 조건이 충족되면 질문이 표시됩니다.
                        <br/>질문번호는 활성화될 때마다 동적으로 번호가 매겨집니다.</li>
                </ul>

                <h6>설문조사 참여기간 설정하기</h6>
                <p>설문조사 기간을 설정할 수 있습니다.
                    <br/>시작일시를 수정하지 않으면, 지금 즉시 생성됩니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.10_4.png"} alt="설문조사 등록 설정"/>

                <h6>설문조사 참여자 설정하기</h6>
                <p>일반적으로 설문조사 참여자를 지정하지 않을 경우, 공개로 생성됩니다.
                    <br/>“홈” 또는 “설문조사” 화면에 설문조사가 노출되며, 노출된 화면을 통해 유저는 참여 할 수 있습니다.
                    <br/>공개로 설정될 경우, 시스템 관리자의 승인 후, 설문조사가 최종적으로 활성화 됩니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.10_5.png"} alt="설문조사 등록 설정"/>
                <p>설문조사를 비공개로 특정 유저만 참여를 원할 경우, 설문조사 참여자를 지정하면 됩니다.
                    <br/>지정할 경우, 지정된 유저에게 SMS 문자 메시지가 전송되어 해당 유저만 참여할 수 있습니다.
                    <br/>또한 비공개로 설정되었으므로 “홈” 또는 “설문조사“ 화면에 해당 설문조사는 노출되지 않습니다.
                </p>

                <p>참여할 대상자를 지정하여 설문조사를 등록하면,  참여자를 지정하는 화면이 호출되어 참여자를 지정할 수 있습니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.10_6.png"} alt="설문조사 등록 설정"/>
                <ul>
                    <li>연락처 그룹 등록 : 미리 연락처 그룹을 등록했다면, 등록된 그룹의 유저가 참여자로 지정되며, 등록된 휴대폰 번호로 SMS 메시지를 전송합니다.</li>
                    <li>대상자 연락처 등록 : 아래 그림과 같이 참여할 대상자의 이름과 연락처를 직접 입력하여 지정할 수 있습니다.
                        <br/>대상자 등록이 완료되면, 등록된 연락처로 SMS 메시지가 전송됩니다.
                    </li>
                </ul>
                <img src={process.env.PUBLIC_URL + "/images/document/2.10_7.png"} alt="설문조사 등록 설정"/>
                <ul>
                    <li>대상자 파일 업로드 : 파일 업로드 기능을 통해서 다수의 대상자를 한번에 등록 할 수 있습니다.
                        <br/>첨부파일 양식을 다운받아 양식에 맞게 대상자 리스트를 추가 후, 파일을 등록하면 됩니다.
                        <br/>대상자 등록이 완료되면, 파일로 등록된 대상자에게 SMS 메시지가 전송됩니다.
                    </li>
                </ul>
                <img src={process.env.PUBLIC_URL + "/images/document/2.10_8.png"} alt="설문조사 등록 설정"/>

                <h6>설문조사 종료 메시지 설정하기</h6>
                <p>설문조사 마지막 질문에 참여하거나 마지막 질문 아래에 설문조사 종료 메시지를 설정하여 참여에 대한 감사 메시지를 설정할 수 있습니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.10_9.png"} alt="설문조사 등록 설정"/>
            </section>

            <section id="section-2-11"><h4>2.11 설문조사 배포</h4>
                <p>“설문조사 등록 설정” 완료 후, 설문조사를 등록하면 아래와 같이 안건이 등록 요청 되었다는 화면이 호출됩니다.
                    <br/>이후, 시스템 관리자에게 문의하거나 시스템 관리자 계정을 통해서 해당 설문조사 안건을 승인하면, 최종적으로 설문조사 등록이 완료되어 배포 됩니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.11_1.png"} alt="설문조사 배포"/>

                <p>만약 설문조사를 비공개로 참여자를 지정하여 참여자 등록까지 완료하였다면, 아래와 같이 초대 링크(SMS)를 전송했다는 알림이
                    호출되며, 즉시 비공개로 시스템 관리자의 승인없이 설문조사 배포가성이 완료 및 배포 됩니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.11_2.png"} alt="설문조사 배포"/>

                <p>등록된 설문조사는 아래와 같이 “홈” 또는 “설문조사” 화면에 노출됩니다.</p>
                <img src={process.env.PUBLIC_URL + "/images/document/2.11_3.png"} alt="설문조사 배포"/>
            </section>
        </>
    )
}

export default SectionTwo;
