import React from 'react';
import LineDraw from "./LineDraw";
import PlatformPanelDataTable from "../../component/product/PlatformPanelDataTable";
import {Link} from "react-router-dom";


const SectionOne = () => {
    return (
        <>
            <section id="section-1">
                <h3 className="mt-0">1 코나체인</h3>
            </section>

            <section id="section-1-1">
                <h4>1.1 코나체인이란?</h4>
                <p>안녕하세요.
                    코나체인은 블록체인 기술을 기반으로 사용자의 개인정보를 보호해 사용자는 개인의 정보 노출 걱정 없이 자유롭게 의견을 표현할 수 있습니다. 분산형 애플리케이션 적용으로 결과에 대한 투명성과 보안성이 보장됩니다.
                    코나체인의 기술과 함께 여러분이 원하시는 설문 조사를 안전하고 편리하게 진행하세요
                </p>
            </section>

            <section id="section-1-2">
                <h4>1-2 Panel 설명</h4>
                <p>
                    <strong className="kc-title-color-blue">803만명</strong> 코나아이에서 진행하는 연중 Mobile 조사 서비스에 참여가 가능하도록 약관 동의가 완료된 패널
                    <br/><strong className="kc-title-color-blue">2만명</strong> 점포 조사를 위하여 지역별로 구축한 가맹점주 패널
                    <br/><strong className="kc-title-color-blue">17만명</strong> 코나카드 결제 정보와 연계 분석이 가능한 전국 패널
                </p>
                <LineDraw/>
                <p className="kc-text-center-and-bold">
                    822만명 코나아이가 보유한 Access Panel Pool
                    <br/>90만명 코나체인 Survey Pool
                </p>

                <h6>코나체인 서베이 Mobile Panel
                    <br/><b>코나체인은 다릅니다.</b>
                </h6>
                <p><b>Panel = 대표성 + 신뢰성</b>
                    <br/><strong className="kc-title-color-blue">대표성 </strong> 실제 인구 분포와 유사한 패널 Pool을 활용하여 지역별 대표성이 높다
                    <br/><strong className="kc-title-color-blue">신뢰성 </strong> 통신사 본인인증이 완료된 지역화폐 활성화(Active) 패널로 구성
                </p>
                <p><b>차별화 = 효율성 + 분석 가능성</b>
                    <br/><strong className="kc-title-color-blue">효율성 </strong> 지역화폐 결제 데이터 기반의 정밀한 지역 주민 Sampling
                    <br/><strong className="kc-title-color-blue">분석 가능성 </strong> 코나아이의 소비 데이터, 공공 데이터 등 실제 Big Data 와의
                    연계 분석
                </p>
                <LineDraw/>
                <p style={{textAlign: "center"}}>
                    <br/>
                    지역별 대표성과 신뢰성이 확보된 패널을 통해 설문당 평균 <b>49,956명</b> 참여
                    <br/><b>코나체인 서베이는 38개 지방자치단체에 도입되어 조사를 수행하고 있습니다.
                    <br/>압도적인 참여도를 바탕으로 가장 믿음직한 결과물을 제공합니다.</b>
                </p>
                <div>
                    <PlatformPanelDataTable/>
                </div>
            </section>


            <section id="section-1-3">
                <h4>1-3 문의하기</h4>
                <p>자세한 내용은  <Link to={process.env.PUBLIC_URL + "/inquiry"}>서비스 문의</Link> 탭을 참고하여 문의 부탁드립니다.</p>
            </section>
        </>
    )
}

export default SectionOne;
