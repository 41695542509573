import React from 'react';


const SurveyPlatformProp = ({colSize, serviceStyle, serviceData}) => {

    return (
		<>
			{serviceData.map((data, index) => (
				<div className={colSize} key={index} style={{width: "20%"}} >
					<div style={{display: "block", padding: "15px 10px"}} className={`services-grid ${serviceStyle}`}>
						<div className="thumbnail kc-center-div-p-90" style={{margin: "auto"}}>
							<img src={process.env.PUBLIC_URL + data.image} alt="icon" />
						</div>
						<div className="content">
							<h6 className="title" style={{color: "var(--color-primary)", textAlign: "center", fontWight: "bold"}}>{data.title}</h6>
							<p style={{textAlign: "center", fontSize: "12px"}} className="kc-box-card-data-p" dangerouslySetInnerHTML={{__html: data.description}}></p>
						</div>
					</div>
			 	</div>
			))}
		</>
    )
}

export default SurveyPlatformProp;