import React from 'react';
import {Link} from 'react-router-dom';
import FormOne from '../contact/FormOne';
import {AnimationOnScroll} from "react-animation-on-scroll";
import {FaArrowRight, FaFileWord} from "react-icons/fa";
import BlockQuote from "../../common/header/BlockQuote";


const BannerHomePageThree = ({
                               backgroundImageUrl
                               , bannerClassName
                               , topSubTitleText
                               , titleText
                               , subTitleText
                               , button1
                               , button2
                               , rightImageUrl
                               , quoteTextList
                           }
) => {
    return (
        <div className= {`banner banner-style-5 ${bannerClassName}`}
             style={{backgroundImage: `url(${process.env.PUBLIC_URL + backgroundImageUrl})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-xl-7">
                        <div className="banner-content">
                            <h5 className="kc-font-color-white"
                                dangerouslySetInnerHTML={{__html: topSubTitleText}}></h5>
                            <br/>
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="kc-title kc-font-color-white"
                                    dangerouslySetInnerHTML={{__html: titleText}}></h1>
                                <h5 className="kc-font-color-white"
                                    dangerouslySetInnerHTML={{__html: subTitleText}}></h5>
                                {button1 &&
                                <Link to={process.env.PUBLIC_URL + "/inquiry"}
                                      className="axil-btn btn-fill-primary btn-large kc-axil-btn kc-font-color-white"
                                      dangerouslySetInnerHTML={{__html: button1}}></Link>
                                }
                                {button2 &&
                                <Link to={process.env.PUBLIC_URL + "/#"}
                                      className="axil-btn btn-fill-primary btn-large kc-axil-btn ml--20 kc-font-color-white"
                                      dangerouslySetInnerHTML={{__html: button2}}></Link>
                                }
                                <br/>

                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-5">
                        {rightImageUrl != null &&
                        <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>
                            <div className="large-thumb">
                                <img src={process.env.PUBLIC_URL + rightImageUrl} alt="KONA CHAIN"/>
                            </div>
                        </AnimationOnScroll>
                        }
                        {quoteTextList != null &&
                        <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>
                            <div className="large-thumb">
                                {
                                    quoteTextList.map((text, item) =>
                                        (item%2===0) ? (<BlockQuote text={text} className={"kc-mb-style-3"}/>) : (<BlockQuote text={text} className={"kc-mb-style-2"}/>)
                                    )
                                }
                            </div>
                        </AnimationOnScroll>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerHomePageThree;
