import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import ElectronicVotingServiceHeader from "../../component/service/ElectronicVotingServiceHeader";
import InquirySection from "../../component/homepage/InquirySection";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";
import QuotesArea from "../../component/service/QuotesArea";
import {Link} from "react-router-dom";


const ElectronicVoting = () => {
    const quoteTextList = [
        "쉽고 간편하게 참여 할 수 없나요?"
        , "온라인투표를 하고싶은데 비용이 걱정이예요."
        , "아파트 동대표를 뽑아야 되는데 투표율이 저조해요."
    ]
    return (
        <>
            <SEO title="코나체인 | 전자 투표"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>

                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/service-bg-4.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--210"
                    titleText = "전자 투표"
                    subTitleText = "주민의 의견이 적극적으로 반영된 대표를 <br/>선출하고 싶으신가요?"
                />

                <QuotesArea
                    bgClassName = "bg-color-light"
                    quotesTitle = "누구나 쉽고 간편하게<br/>투표를 진행할 수 있습니다."
                    quotesImageUrl = "/images/service/quotes_electronic-voting.svg"
                />

                <ElectronicVotingServiceHeader/>

                <InquirySection/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default ElectronicVoting;
