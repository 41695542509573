import React from 'react';


const TimeToThrowServiceHeader = () => {
    return (
        <>
            <div className="service-scroll-navigation-area kc-pt--90">
                <div className="container">
                    <div className="row g-0">
                        <div>
                            <h2 className="kc-title">‘인센티브 지급형 환경 종합 플랫폼’
                                <br/>환경보호 활동에 즉시 보상을 지급합니다.
                            </h2>
                            <p>환경을 보호하기 위한 온라인 학습., 오프라인 활동에 대한 <span className="kc-highlight-yellow">인센티브</span>를
                                <span className="kc-highlight-yellow">지급</span>하여 시민들의 <span
                                    className="kc-highlight-yellow">높은 참여</span>를 격려합니다.</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 " style={{textAlign: "left", paddingLeft: "100px"}}>
                                <img src="/images/service/kttt-image-2.png" alt=""/>
                            </div>
                            <div className="col-lg-6 order-lg-1 kc-center-div-p-50">
                                <img src="/images/service/kttt-image-3.png" alt=""/>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="service-scroll-navigation-area pb--60">
                <div className="container">
                    <div className="row g-0">
                        <div>
                            <h2 className="kc-title">사례</h2>
                        </div>
                        <img src="/images/service/kttt-image-4.png" alt=""/>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TimeToThrowServiceHeader;
