import React from "react";

function BackToTop() {
    return (
        <div className="flex mx-auto h-6 w-6">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"
                 className="fill-th-accent-light stroke-th-accent-light" stroke-width="1px">
                <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z"></path>
            </svg>
        </div>
    );
}

export default BackToTop;