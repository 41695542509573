import React from 'react';
import {Link} from 'react-router-dom';
import ServiceStudyData from '../../data/homepage/ServiceData.json';

const allData = ServiceStudyData;

const HomePageServiceInfo = () => {

    const CaseLeftThumb = ({data}) => {
        return (
            <>
            <div className="col-lg-6">
                <div className={`case-study-featured-thumb thumb-${data.id}`}>
                    <img src={process.env.PUBLIC_URL + data.thumb} alt={data.title} width={630} height="auto" />
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1 kc-vertical-center">
                <div className="case-study-featured">
                <div className="section-heading heading-left">
                    <span className="subtitle" dangerouslySetInnerHTML={{__html: data.subtitle}}></span>
                    <h2 className="kc-title" dangerouslySetInnerHTML={{__html: data.title}}></h2>
                    <p dangerouslySetInnerHTML={{__html: data.excerpt}}></p>
                    <Link to={process.env.PUBLIC_URL + "/" + `${(data.more_link)}`} className="axil-btn kc-axil-btn-2 btn-fill-primary btn-large">더 알아보기</Link>
                </div>

                </div>
            </div>
            </>
        )
    }

    const CaseRightThumb = ({data}) => {
        return (
            <>
            <div className="col-lg-6 offset-xl-1 order-lg-2">
                <div className={`case-study-featured-thumb thumb-${data.id}`}>
                    <img src={process.env.PUBLIC_URL + data.thumb} alt={data.title} width={630} height="auto" />
                </div>
            </div>
            <div className="col-lg-5 order-lg-1 kc-vertical-center">
                <div className="case-study-featured">
                <div className="section-heading heading-left">
                    <span className="subtitle" dangerouslySetInnerHTML={{__html: data.subtitle}}></span>
                    <h2 className="kc-title" dangerouslySetInnerHTML={{__html: data.title}}></h2>
                    <p dangerouslySetInnerHTML={{__html: data.excerpt}}></p>
                    <Link to={process.env.PUBLIC_URL + "/" + `${(data.more_link)}`} className="axil-btn kc-axil-btn-2 btn-fill-primary btn-large">더 알아보기</Link>
                </div>

                </div>
            </div>
            </>
        )
    }

    return (

        <div className="kc-pt--90 pt_sm--80 case-study-page-area">
            <div className="container">
                {allData.map((data) => (
                    <div className="row" key={data.id}>
                        {(data.id % 2  === 0) ? <CaseLeftThumb data={data}/> : <CaseRightThumb data={data}/>}
                    </div>
                ))}
            </div>
        </div>

    )
}

export default HomePageServiceInfo;
