import React from 'react';
import HomePageBlogData from '../../data/homepage/HomePageBlogData.json';
import HomePageBlogProp from "./HomePageBlogProp";

const homePageBlogData = HomePageBlogData;
const HomePageBlogSection = () => {
    return (

        <div className="section kc-pt--90 pb--60 bg-color-light" id="section3">
            <div className="container">
                <div className="section-heading heading-left">
                    <h2 className="kc-title">
                        코나체인의 다양한 소식과 분석자료를 확인 해 보세요!
                    </h2>
                </div>
                <div className="row">
                    <HomePageBlogProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={homePageBlogData}/>
                </div>
            </div>
        </div>

    )
}

export default HomePageBlogSection;