import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaDribbble } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";
import BackToTop from "../../elements/switcher/BackToTop";


const SplashFooter = () => {
    return (
        <footer className="footer-area splash-footer">
            <div className="container">
                <div className="footer-bottom" style={{borderTop: "1px solid var(--color-ghost);"}}>
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                        <div className="footer-copyright">
                            <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://konachain.com/">KONA CHAIN Co. Ltd.</a></span>
                        </div>
                        </div>
                        <div className="col-lg-2">
                        {/*<ul className="footer-social list-unstyled">*/}
                        {/*    <li><a href="https://www.facebook.com/axilthemes"><FaFacebookF /></a></li>*/}
                        {/*    <li><a href="https://dribbble.com/axilweb/"><FaDribbble /></a></li>*/}
                        {/*</ul>*/}
                        </div>
                        <div className="col-lg-5">
                        <div className="footer-bottom-link">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"} >개인정보처리방침 +</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/terms-use"} >이용약관</Link></li>
                                {/*<li><Link to={process.env.PUBLIC_URL + "/voting-service-documents"} >고객센터</Link></li>*/}
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop className={"scroll-to-top-custom"} smooth component={<BackToTop />} />
        </footer>

    )
}

export default SplashFooter;
