import React from 'react';
import quote from '../../assets/css/kc-quote.css';
const BlockQuote = ({text, className}) => {
    return (
        <>
            <div className={`kc-mb-wrap ${className}`}>
                <blockquote>
                    <p>{text}</p>
                </blockquote>
            </div>
        </>
    )
}
export default BlockQuote;
