import {api} from "./";
import {paramUtil} from "../helpers/paramUtil";

export const blogApi = {
    getBlogList
    ,getBlogDetails
}

function getBlogList(requestPayload) {
    return api.get('/api/post/get-list', paramUtil.stringifyRequestPayload(requestPayload))
}

function getBlogDetails(requestPayload) {
    return api.get('/api/post/get/details', paramUtil.stringifyRequestPayload(requestPayload))
}
