import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="코나체인 | 개인정보처리방침" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600"
                    titleText="개인정보처리방침"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <p>
                                        <br/>코나체인 주식회사(이하 “회사”라 한다)는 ‘KONA CHAIN’를 이용하는 이용자의 개인정보를 중요시하며, 개인정보보호법 등 관계 법령상의 개인정보보호 규정을 준수하고 있습니다. 회사는 서비스*를 제공하는 어플리케이션의 화면 또는 회사 홈페이지 등(이하 “어플리케이션 화면 등”)을 통하여 개인정보처리방침을 상시 고지하고 있으며, 개인정보처리방침을 개정하는 경우 관계법령이 정하는 절차에 따라 변경 사유와 내용을 어플리케이션 화면 등을 통하여 공지하고 있습니다.
                                        <br/>* 이하 서비스는 ‘KONA CHAIN’를 통칭합니다.
                                        <br/>
                                        <br/>제1조(수집하는 개인정보의 항목 및 수집 방법)
                                        <br/>회사는 아래와 같이 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.
                                        <br/>① 개인정보 수집항목
                                        <br/>1. 회원가입 시
                                        <br/>- 필수: 성명, 휴대전화번호, 이메일주소, 비밀번호, 단말기 정보(단말기 모델, 이동통신사 정보), 본인확인값(CI, DI)
                                        <br/>2. 서비스 이용, 처리과정 시
                                        <br/>- 필수: 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, MAC 주소, 운영체제 종류 및 버전 정보, 기기 정보, 본인 확인 값(CI, DI),
                                        <br/>② 개인정보 수집방법
                                        <br/>1. 어플리케이션, 홈페이지, 서면, 전자우편, 팩스, 전화 등을 통해 이용자가 제시
                                        <br/>2. 컴퓨터나 휴대전화 등 모바일 기기의 자동수집 장치를 통한 수집
                                        <br/>
                                        <br/>회사는 개인을 식별할 수 없는 온라인상의 이용자 활동정보(행태정보)를 협력회사로부터 제공 받거나 생성정보 수집 툴 등을 통해 직접 수집할 수 있으며, 회사가 보유한 개인(식별)정보와 결합 하여 처리할 경우 본 동의 약관에서 정한 이용 목적 및 보유기간을 따릅니다.
                                        <br/>또한, 회사는 서비스 이용 도중 추가적인 개인정보를 수집할 수 있으며, 이 경우 별도의 개인정보 수집 및 이용 동의를 받습니다.
                                        <br/>
                                        <br/>제2조(개인정보의 수집 및 이용목적)
                                        <br/>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
                                        <br/>① 서비스 이용자 관리 및 서비스 제공
                                        <br/>1. 서비스 제공에 관한 계약 체결, 이행 및 해지를 위한 이용자 식별
                                        <br/>2. 실명인증 서비스 이용 시 본인확인
                                        <br/>3. 서비스 이용 관련 상담, 민원 처리분쟁 조정을 위한 기록 보존
                                        <br/>4. 서비스 변경 등 고지사항 전달
                                        <br/>5. 이용자의 서비스 부정 이용 또는 비인가 사용 방지
                                        <br/>6. 분쟁 조정을 위한 기록 보존
                                        <br/>② 신규 서비스 개발 및 마케팅·광고에 활용
                                        <br/>1. 서비스 이용에 대한 통계 수집 등을 통한 신규 서비스 개발 및 맞춤 서비스 제공
                                        <br/>2. 이벤트 등 광고성 정보 전달과 참여기회 제공
                                        <br/>
                                        <br/>제3조(개인정보의 제3 자 제공)
                                        <br/>회사는 이용자의 개인정보를 위 제 2 조 “개인정보의 수집 및 이용 목적”에서 알린 범위 내에서 사용하며, 원칙적으로 법령에 근거가 없거나 이용자의 사전 동의 없이는 고지한 범위를 초과하여 이용하거나 외부에 제공하지 않습니다.
                                        <br/>① 업무 제휴
                                        <br/>회사는 이용자에게 보다 나은 서비스 제공을 위하여 이용자의 개인정보를 외부 협력업체(이하 “제휴사”라 한다)에게 제공하거나 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 정보를 제공받는 제휴사, 제공하는 개인정보 항목, 제휴사의 개인정보 이용 목적, 제휴사의 개인정보 보유 및 이용기간, 정보제공 동의에 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익을 명시하여 전자우편 또는 서면을 통해 이용자에게 사전 동의를 구하고 있습니다. 회사는 제휴 관계가 변경될 때에도 같은 절차에 의하여 고지하거나 동의를 구하고 있습니다.
                                        <br/>② 법령상 근거
                                        <br/>관계 법령에 의하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                                        <br/>③ 회사의 권리 및 의무 이전
                                        <br/>회사의 권리와 의무가 합병, 영업 양수 등의 사유로 인해 승계되거나 이전되는 경우 회사는 정보통신망 이용촉진 및 정보보호에 관한 법률 등 관계 법령에서 정한 절차와 방법에 의해
                                        <br/>개인정보 이전 사실을 사전에 고지하고 이용자의 개인정보 이전에 대한 동의 철회권을 부여합니다.
                                        <br/>
                                        <br/>제4조(개인정보의 처리 위탁)
                                        <br/>회사는 서비스 제공을 위해 필요한 업무의 일부를 아래와 같이 외부 협력업체(이하 “수탁자”라 한다)에 위탁하고 있으며, 위탁계약 체결 시 개인정보보호법 등 관계 법령에 따라 개인정보가 안전하게 처리될 수 있도록 필요한 사항을 계약서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 회사는 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 어플리케이션 화면 등에 게시된 개인정보처리방침을 통하여 공지하거나 전자우편을 통해 이용자에게 통지하고 있습니다. 다만 마케팅, 이벤트 행사로 인한 경품발송업무 위탁 시 이용자의 별도 동의를 받아 처리하고 있습니다. 현재 회사가 업무를 외부에 위탁하고 있는 내역은 아래와 같습니다.
                                        <br/>
                                        <br/>수탁업체
                                        <br/>(주)케이지모빌리언스
                                        <br/>위탁업무 내용 : 이용자 본인 인증
                                        <br/>개인정보 보유 및 이용기간 : 위탁자가 이미 보유한 개인정보로 별도 저장하지 않음
                                        <br/>(주)㈜플라이하이
                                        <br/>위탁업무 내용 : 실명인증 서비스 이용 시 신분증을 통한 본인확인
                                        <br/>개인정보 보유 및 이용기간 : 회원탈퇴 시 혹은 위탁계약 종료 시까지
                                        <br/>나래인터넷㈜
                                        <br/>위탁업무 내용 : 080 ARS 수신거부
                                        <br/>개인정보 보유 및 이용기간 : 해당서비스 또는 위탁계약 종료 시까지
                                        <br/>㈜휴머스온
                                        <br/>위탁업무 내용 : SMS/이메일/알림톡 전송 중계
                                        <br/>개인정보 보유 및 이용기간 : 회원탈퇴 시 혹은 위탁계약 종료 시까지
                                        <br/>㈜비지에프네트웍스
                                        <br/>위탁업무 내용 : SMS전송 중계
                                        <br/>개인정보 보유 및 이용기간 : 회원탈퇴 시 혹은 위탁계약 종료 시까지
                                        <br/>
                                        <br/>제5조(개인정보의 보유 및 이용기간)
                                        <br/>회사는 원칙적으로 이용자에게 동의 받은 개인정보 수집 및 이용목적이 달성된 후 해당 정보를 지체 없이 파기합니다. 단, 내부 방침에 의하거나 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 일정한 기간 동안 이용자의 개인정보를 보관합니다.
                                        <br/>① 내부 방침에 의한 정보 보유 사유
                                        <br/>1. 부정 이용 기록
                                        <br/>- 보존 이유: 반복적 임의 해지 통한 경제상 이익 불·편법적으로 수취하는 행위 방지 및   차단된 계정 재가입 통한 부정 이용 방지
                                        <br/>- 보존 기간: 1 년
                                        <br/>2. 전자우편 주소, 아이디, 전화번호
                                        <br/>- 보존 이유: 삭제된 회원의 민원처리 및 제휴 기관과의 거래 대금 정산 등 기타 필요한 경우 대비 목적
                                        <br/>- 보존 기간: 1 년
                                        <br/>② 관련 법령에 의한 정보 보유 사유
                                        <br/>1. 계약 또는 청약철회 등에 관한 기록
                                        <br/>- 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률
                                        <br/>- 보존 기간: 5 년
                                        <br/>2. 대금결제 및 재화 등의 공급에 관한 기록
                                        <br/>- 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률
                                        <br/>- 보존 기간: 5 년
                                        <br/>3. 소비자의 불만 또는 분쟁 처리에 관한 기록
                                        <br/>- 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률
                                        <br/>- 보존 기간: 3 년
                                        <br/>4. 건당 거래금액이 1만원을 초과하는 전자금융 거래에 관한 기록
                                        <br/>- 보존 근거: 전자금융거래법시행령
                                        <br/>- 보존 기간: 5 년
                                        <br/>5. 건당 거래금액이 1만원 이하인 전자금융 거래에 관한 기록
                                        <br/>- 보존 근거: 전자금융거래법시행령
                                        <br/>- 보존 기간: 1 년
                                        <br/>6. 홈페이지 방문기록
                                        <br/>- 보존 근거: 통신비밀보호법
                                        <br/>- 보존 기간: 3 개월
                                        <br/>7. 본인확인에 관한 기록
                                        <br/>- 보존 근거: 정보통신망 이용촉진 및 정보보호 등에 관한 법률
                                        <br/>- 보존 기간: 6개월
                                        <br/>8. 개인위치정보에 관한 기록
                                        <br/>- 보존 근거: 위치정보의 보호 및 이용 등에 관한 법률
                                        <br/>- 보존 기간: 6 개월
                                        <br/>
                                        <br/>제6조(개인정보의 파기)
                                        <br/>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 아래와 같습니다.
                                        <br/>① 파기절차
                                        <br/>이용자가 서비스 가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB(종이의 경우 별도의 서류함) 로 옮겨져 회사 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 제 5 조에 기재한 바와 같이 일정 기간 저장된 후 파기됩니다. 별도 DB 로 옮겨진 개인정보는 법령 또는 이용자의 동의에 의한 경우가 아니고서는 보유 이외의 다른 목적으로 이용되지 않습니다.
                                        <br/>② 파기방법
                                        <br/>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                                        <br/>
                                        <br/>제7조(이용자 및 법정대리인의 권리와 그 행사방법)
                                        <br/>① 이용자 및 법정대리인은 언제든지 자신 혹은 만 14 세 미만 아동의 개인정보를 조회하거나 수정할 수 있고, 서비스 가입 해지(회원탈퇴)의 방법으로 개인정보 수집·이용·제공 등의 동의
                                        <br/>철회를 할 수 있습니다. 다만, 회사는 서비스에 필요한 필수정보만을 수집·이용하므로 동의의 일부 철회는 불가능합니다.
                                        <br/>② 이용자가 가입 해지를 원하시면 어플리케이션에서 “회원 탈퇴” 메뉴를 통해 직접 탈퇴가 가능하며, 직접 탈퇴가 어려운 경우 고객센터 또는 개인정보보호책임자에게 서면, 전화 또는 전자우편으로 연락하시면 지체 없이 조치하겠습니다.
                                        <br/>③ 이용자가 개인정보 수집·이용·제공 등의 동의를 하신 현황, 동의를 통해 수집된 개인정보, 해당 개인정보에 대한 이용 및 제 3 자 제공현황 등에 대한 정보를 열람 또는 제공받기를 원하는 경우에도 고객센터 또는 개인정보보호책임자에게 연락하면 지체 없이 조치하겠습니다.
                                        <br/>④ 이용자가 개인정보의 오류에 대한 정정을 요청하는 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용하거나 제 3 자에게 제공하지 않습니다. 또한 잘못된 개인정보를 제 3 자에게 이미 제공한 경우에는 정정 처리결과를 제 3 자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
                                        <br/>⑤ 회사는 이용자 혹은 법정대리인의 요청에 의해 해지 또는 삭제된 개인정보는 제 5 조(개인정보의 보유 및 이용기간)에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                                        <br/>
                                        <br/>제8조(개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)
                                        <br/>이용자가 당 서비스의 어플리케이션 화면 등에 접속하는 경우, 회사는 개인화되고 맞춤화 된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 ‘쿠키’)를 사용할 수 있습니다. 쿠키란 서비스를 운영하는데 이용되는 서버가 브라우저에 보내는 아주 작은 텍스트 파일로서 컴퓨터의 하드디스크 또는 기타 접속매체(스마트폰, 태블릿 PC 등의 모바일기기 포함)의 내부 저장장치 등에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용하며, 설정을 통해 사용을 거부할 수 있습니다.
                                        <br/>① 쿠키 등 사용 목적
                                        <br/>회사는 이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 등 개인에 최적화된 맞춤형 정보를 제공하기 위하여 쿠키를 사용합니다.
                                        <br/>② 쿠키 설정 거부 방법
                                        <br/>이용자는 쿠키 설정에 대한 선택권을 가지고 있습니다. 따라서, 이용하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
                                        <br/>쿠키의 저장을 거부할 수도 있습니다. 다만, 이용자가 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
                                        <br/>
                                        <br/>제9조(개인정보의 기술적·관리적 보호대책)
                                        <br/>회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적·관리적 대책을 강구하여 실행하고 있습니다.
                                        <br/>① 개인정보 암호화
                                        <br/>회사는 관계법령 및 관련 행정기관의 지침에 따른 모든 개인정보 항목에 대해서 암호화하여 보관합니다.
                                        <br/>② 해킹 등에 대비한 기술적 대책
                                        <br/>회사는 서버, 네트워크 등의 해킹을 통해 이용자의 개인정보가 유출되는 것을 방지하기 위해 침입차단시스템 및 최신 보안 프로그램을 설치하여 운영합니다.
                                        <br/>③ 취급 직원 최소화 및 교육 실시
                                        <br/>회사는 이용자의 개인정보 유출을 최소화하기 위해 개인정보에 접근할 수 있는 직원을 담당자에 한정시켜 최소화하고, 보안 중요성에 대해 주기적인 교육을 실시하는 한편 개인정보 보호정책의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.
                                        <br/>
                                        <br/>제10조(개인정보보호책임자 및 담당부서)
                                        <br/>회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 민원을 처리하기 위하여 아래와 같이 개인정보보호책임자 및 담당부서를 지정하고 있습니다. 회사는 이용자들의 민원사항에 대해 신속하게 조치하고 충분한 답변을 드릴 것입니다.
                                        <br/>① 개인정보보호책임자 및 담당부서
                                        <br/>- 책임자: 한상훈
                                        <br/>- 담당부서: 코나체인 서비스 담당
                                        <br/>- 전화번호: 1668-3610
                                        <br/>- 전자우편: consulting@konachain.com
                                        <br/>② 개인정보 침해 신고 및 상담
                                        <br/>기타 개인정보 침해에 대한 신고·상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                                        <br/>- 개인정보 침해신고센터/분쟁조정위원회 (privacy.kisa.or.kr / 국번없이 118)
                                        <br/>- 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
                                        <br/>- 경찰청 사이버안전국 (www.ctrc.go.kr / 국번없이 182)
                                        <br/>
                                        <br/>제11조(적용 범위)
                                        <br/>① 본 개인정보처리방침은 회사가 서비스하는 브랜드 중 하나인 ‘대덕 e 로움 서비스' 범위에 적용되며, 회사의 다른 브랜드 서비스에 대하여는 별개의 개인정보 처리방침이 적용될 수 있습니다.
                                        <br/>② 회사는 이용자에게 타사의 홈페이지, 어플리케이션 또는 자료(이하 “어플리케이션 등”)에 대한 링크를 표시할 수 있으나, 이 경우 회사는 외부 어플리케이션 등에 대한 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성을 책임질 수 없으며 해당 어플리케이션 등에는 본 개인정보 처리방침이 적용되지 않습니다. 표시된 링크를 클릭하여 타사의 어플리케이션 등으로 옮겨가는 경우 해당 어플리케이션 등의 개인정보보호 정책은 회사와 무관하므로 새로 방문한 해당 어플리케이션 등의 정책을 검토해 보시기 바랍니다.
                                        <br/>
                                        <br/>제12조(고지의무)
                                        <br/>본 개인정보 처리방침 내용의 변경이 있을 시에는 개정 최소 7 일 전부터 어플리케이션 화면 등을 통하여 고지할 것입니다. 다만, 이용자에게 불리하게 개인정보의 수집 및 활용, 제 3 자 제공 등 개인정보처리 내용을 변경하는 경우에는 최소한 30 일 이상의 사전 유예 기간을 두고 공지하겠습니다.
                                        <br/>
                                        <br/>- 시행일자: 2022년 12월 20일
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;
