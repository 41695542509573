import React from 'react';
import HomePageBusInfoDataProp from "./HomePageBusInfoDataProp";
import HomePageBlogData from '../../data/homepage/HomePageBusInfoData.json';

const homePageBlogData = HomePageBlogData;
const HomePageBusinessInfoSection = () => {
    return (

        <div className="section kc-pt--90 pb--60 bg-color-light">
            <div className="container">
                <div className='row'>
                    <HomePageBusInfoDataProp colSize="col-xl-4 col-md-6" serviceStyle="service-style-2" itemShow="6" serviceData={homePageBlogData} />
                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                {/*<li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>*/}
                {/*<li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>*/}
                {/*<li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>*/}
            </ul>
        </div>

    )
}

export default HomePageBusinessInfoSection;