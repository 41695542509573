import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <Link to="#">서비스 <FaAngleDown /> </Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/marketing-research"}>기업 리서치</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/region-based-research"}>지역기반의 공공 리서치</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/electronic-voting"}>전자 투표</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/time-to-throw-away"}>환경 종합 관리 서비스</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/blockchain-baas-service"}>블록체인 BaaS</Link></li>
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">플랫폼 <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/survey-platform"}>설문조사 플랫폼</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/voting-platform"}>투표 플랫폼</Link></li>
                        {/*<li><Link to={process.env.PUBLIC_URL + "/result-analysis-platform"}>데이터 분석 플랫폼</Link></li>*/}
                        <li><Link to={process.env.PUBLIC_URL + "/time-to-throw-away-platform"}>환경 종합 관리 플랫폼</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/blockchain-baas-pt"}>블록체인 BaaS 플랫폼</Link></li>
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">블로그 <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/kona-chain-news"}>코나체인 소식</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/trend-report"}>트렌드 리포트</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/research-tips"}>리서치 팁</Link></li>
                        {/*<li><Link to={process.env.PUBLIC_URL + "/kona-chain-article"}>공지사항</Link></li>*/}
                    </ul>
                </li>
                {/*<li><Link to={process.env.PUBLIC_URL + "/pricing-table"}>서비스/가격</Link></li>*/}
                <li><Link to={process.env.PUBLIC_URL + "/inquiry"}>서비스 문의</Link></li>

                <li className="menu-item-has-children">
                    <Link to="#">사용자 가이드 <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/voting-service-documents"}>설문조사&투표 서비스</Link></li>
                        {/*<li><Link to={process.env.PUBLIC_URL + "/contact"}>버릴시간 서비스</Link></li>*/}
                    </ul>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;
