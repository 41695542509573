import React from 'react';
import {FaDotCircle} from "react-icons/fa";


const SectionTwoTableC = () => {
    return (
        <>

            <table className="table table-bordered" >
                <thead>
                <tr className="table-light align-middle">
                    <th scope="col" style={{width: "20%"}}>연산자</th>
                    <th scope="col" style={{width: "80%"}}>설명</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="align-middle" align={"center"}>OR</td>
                    <td className="align-middle" align={"center"}>“또는”, 설정된 조건 중 하나라도 만족해도 설정된 질문이 활성화 됩니다.</td>
                </tr>
                <tr>
                    <td className="align-middle" align={"center"}>AND</td>
                    <td className="align-middle" align={"center"}>“그리고”, 설정된 조건이 모두 만족해야 설정된 질문이 활성화 됩니다.</td>
                </tr>
                <tr>
                    <td className="align-middle" align={"center"}>NOT</td>
                    <td className="align-middle" align={"center"}>“~가 아닌”, 설정된 조건에 해당하지 않는 모든 경우에 설정된 질문이 활성화 됩니다</td>
                </tr>
                <tr>
                    <td className="align-middle" align={"center"}>( )</td>
                    <td className="align-middle" align={"center"}>구분자, 각각의 조건을 구분짓거나 우선순위를 명시할 때 사용됩니다.</td>
                </tr>
                <tr>
                    <td className="align-middle" align={"center"}>Q</td>
                    <td className="align-middle" align={"center"}>질문, 질문번호를 명시하기 위해 번호 앞에 사용되는 키워드입니다.</td>
                </tr>
                <tr>
                    <td className="align-middle" align={"center"}>A</td>
                    <td className="align-middle" align={"center"}>선택항목, 선택항목을 명시하기 위해 번호 앞에 사용되는 키워드 입니다.</td>
                </tr>
                </tbody>
            </table>

        </>
    )
}

export default SectionTwoTableC;
