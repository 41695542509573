import React, {useEffect, useState} from 'react';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import {Link, useParams} from "react-router-dom";
import {blogApi} from "../../api/blogApi";
import {DateUtil} from "../../helpers/DateUtil";
import AttachmentListGenerate from "../../component/blog/AttachmentListGenerate";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";


const KonaChainNewsDetails = () => {
    const params = useParams();
    const inquiryId = parseInt(params.id);

    const [searchParams, setSearchParams] = useState({
        postId:inquiryId
    });

    const [attachmentList, setAttachmentList] = useState([]);

    useEffect(() => {
        handleSearch();
    }, [inquiryId])

    const [blogDetails, setBlogDetails] = useState({
        id: "",
        title: "",
        titleImage: "",
        description: "",
        postDate: "",
        authorId: "",
        reviewerId: null,
        reviewerNote: null,
        publishedDate: null,
        status: "",
        version: "",
        createdAt: "",
        updatedAt: "",
        authorInfo: null,
        reviewerInfo: null,
        category: null,
        tagList: [],
        hasAttachment:false,
        attachmentList: []
    });

    const handleSearch = () => {
        blogApi.getBlogDetails(searchParams)
            .then(res => {
                    setBlogDetails(prevState => {
                        return {
                            ...prevState,
                            ...res.data
                        }
                    })
                setAttachmentListData(res.data.attachmentList);
                }
            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };


    const setAttachmentListData = (attachmentList) => {
        let currentAttachmentList = [];
        attachmentList.map((attachment) => {
            currentAttachmentList.push(attachment.fileUrl);
        })
        setAttachmentList(currentAttachmentList);
    }

    return (
        <>
            <SEO title="코나체인" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600"
                    titleText="코나체인 소식"
                />

                <div className="section mt--80">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 mb--100">
                                <div className="team-details-content">
                                    <h4 style={{borderBottom: "2px solid var(--color-primary)", margin: "0 0 10px 0", paddingBottom: "10px"}}
                                        dangerouslySetInnerHTML={{__html: blogDetails.title}}>
                                    </h4>

                                    <ul className="social-share list-unstyled">
                                        <li>작성일 : {DateUtil.format(blogDetails.postDate)}</li>
                                    </ul>
                                    <p dangerouslySetInnerHTML={{__html: blogDetails.description}}></p>

                                    {blogDetails.hasAttachment &&
                                       <>
                                           <h6>Attachment</h6>
                                           <div className="row">
                                               <div className="col-md-12 col-sm-6 mb--10">
                                                   <ol className="list-style">
                                                    <AttachmentListGenerate attachmentObject={attachmentList}/>
                                                   </ol>
                                               </div>
                                           </div>
                                       </>
                                    }


                                    <div className="mt--20">
                                        <Link to={process.env.PUBLIC_URL + "/kona-chain-news"}>
                                            <button type="button" className="btn btn-dark">List</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default KonaChainNewsDetails;
