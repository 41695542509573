import React from 'react';
import {FaDotCircle} from "react-icons/fa";


const SectionTwoTableA = () => {
    return (
        <>

            <table className="table table-bordered" >
                <thead>
                <tr className="table-light align-middle">
                    <th scope="col" style={{width: "40%"}}></th>
                    <th scope="col" style={{width: "15%"}}>1. 삼성</th>
                    <th scope="col" style={{width: "15%"}}>2. SK</th>
                    <th scope="col" style={{width: "15%"}}>3. 네이버</th>
                    <th scope="col" style={{width: "15%"}}>4. 카카오</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="align-middle">1-1 업계를 선도하는 회사다</td>
                    <td className="align-middle" align={"center"}>1</td>
                    <td className="align-middle" align={"center"}>2</td>
                    <td className="align-middle" align={"center"}>3</td>
                    <td className="align-middle" align={"center"}>4</td>
                </tr>
                <tr>
                    <td className="align-middle">1-2. 차별적인 회사다</td>
                    <td className="align-middle" align={"center"}>1</td>
                    <td className="align-middle" align={"center"}>2</td>
                    <td className="align-middle" align={"center"}>3</td>
                    <td className="align-middle" align={"center"}>4</td>
                </tr>
                <tr>
                    <td className="align-middle">1-3. 혁신적인 회사다</td>
                    <td className="align-middle" align={"center"}>1</td>
                    <td className="align-middle" align={"center"}>2</td>
                    <td className="align-middle" align={"center"}>3</td>
                    <td className="align-middle" align={"center"}>4</td>
                </tr>
                </tbody>
            </table>

        </>
    )
}

export default SectionTwoTableA;
