import React from 'react';

const HomePageASPKAList = () => {
    return (
        <section className="section pb--90 kc-pt--90">
            <div className="container">
                <div className="section-heading">
                    <h2 className="kc-title">많은 공공기관 및 기업이<br/>
                        코나체인을 통해 지역주민의 목소리를 듣고 계십니다
                    </h2>
                </div>
                <div className={`row`}>

                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/1인천e음.png" alt="인천e음" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/2양산사랑상품권.png" alt="양산사랑상품권" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/21동두천.png" alt="동두천" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/18하남.png" alt="하남" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/34고성군.png" alt="고성군" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/8동백전.png" alt="동백전" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/22연천.png" alt="연천" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/35의성군.png" alt="의성군" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/9밀양.png" alt="밀양" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/4대덕e로움.png" alt="대덕e로움" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/15평택.png" alt="평택" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/14안성.png" alt="안성" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/10영월군.png" alt="영월군" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/28서로이음.png" alt="서로이음" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/36음성군.png" alt="음성군" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/20양주.png" alt="양주" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/11수원시.png" alt="수원시" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/7천안사랑카드.png" alt="천안사랑카드" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/23포천.png" alt="포천" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/33울진군.png" alt="울진군" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/32횡성군.png" alt="횡성군" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/5청주페이.png" alt="청주페이" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/6강릉페이.png" alt="강릉페이" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/29의왕시.png" alt="의왕시" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/12화성시.png" alt="화성시" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/26안산.png" alt="안산" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/19의정부.png" alt="의정부" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/3경기지역화폐.png" alt="경기지역화폐" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/31영광군.png" alt="영광군" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/13오산.png" alt="오산" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/30과천시.png" alt="과천시" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/17여주.png" alt="여주" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/25남양주.png" alt="남양주" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/27용산.png" alt="용산" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/16이천.png" alt="이천" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/24구리.png" alt="구리" width={190} height={66}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="project-grid">
                            <div className="kc-thumbnail">
                                <img src="/images/kc-project/37코나카드.png" alt="코나카드" width={190} height={66}/>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </section>
    )
}

export default HomePageASPKAList;
