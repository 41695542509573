import React from 'react';
import {Link} from 'react-router-dom';


const WidgetCategory = () => {

    return (
        <ul className="category-list list-unstyled">
                <li>
                    <Link to={process.env.PUBLIC_URL + "/kona-chain-news"}>코나체인 소식</Link>
                    <Link to={process.env.PUBLIC_URL + "/trend-report"}>트렌드 리포트</Link>
                    <Link to={process.env.PUBLIC_URL + "/research-tips"}>리서치 팁</Link>
                </li>
        </ul>
    )
}

export default WidgetCategory;