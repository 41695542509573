import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import BlogSidebar from '../../component/blog/BlogSidebar';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import {blogApi} from "../../api/blogApi";
import {DateUtil} from "../../helpers/DateUtil";
import TagPreview from "../../component/blog/TagPreview";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";


const BlogDetailsPage = () => {

    const {blogId} = useParams();

    function SlickNextArrow(props) {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}><FaAngleRight /></div>
        );
    }

    function SlickPrevArrow(props) {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}><FaAngleLeft /></div>
        );
    }

    useEffect(() => {
        handleSearch(blogId);
    }, [blogId])

    const [blogDetails, setBlogDetails] = useState({
        id: "",
        title: "",
        titleImage: "/images/blog/blog-8.png",
        description: "",
        postDate: "",
        authorId: "",
        reviewerId: null,
        categoryId: null,
        reviewerNote: null,
        publishedDate: null,
        status: "",
        version: "",
        createdAt: "",
        updatedAt: "",
        authorInfo: null,
        reviewerInfo: null,
        category: null,
        tagList: [],
        attachmentList: []
    });

    const handleSearch = (blogId) => {
        let searchParams = {
            postId:blogId
        }
        console.log("calling handleSearch ... ", {searchParams});
        blogApi.getBlogDetails(searchParams)
            .then(res => {
                setBlogDetails(prevState => {
                        return {
                            ...prevState,
                            ...res.data
                        }
                    })
                }
            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };

    var slideSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,

      };

    return (
        <>
            <SEO title="코나체인" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600"
                    titleText="코나체인 소식"
                />
                <div className="section-padding-equal">
                    <div className="container">
                        <div className="row row-40">
                            <div className="col-lg-8">
                                <div className="single-blog">
                                    <div className="single-blog-content blog-grid">
                                        <h4 style={{borderBottom: "2px solid var(--color-primary)", margin: "0 0 10px 0", paddingBottom: "10px"}}
                                            dangerouslySetInnerHTML={{__html: blogDetails.title}}>
                                        </h4>
                                        <div className="author">
                                            <div className="author-thumb">
                                            </div>
                                            <div className="info">
                                                {/*<h6 className="author-name">{blogDetails.authorInfo? blogDetails.authorInfo.name : ""}</h6>*/}
                                                <ul className="blog-meta list-unstyled">
                                                    <li>{DateUtil.format(blogDetails.postDate)}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="post-thumbnail">
                                            <img width="100%" src={`${blogDetails.titleImage}`}
                                                 onError={(e) => (e.target.src = "/images/blog/default_image.png")}
                                            />
                                        </div>
                                        <div dangerouslySetInnerHTML={{__html: blogDetails.description}}></div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <BlogSidebar categoryId={blogDetails.categoryId} />
                            </div>
                        </div>
                    </div>
                    <TagPreview tagList = {blogDetails.tagList} />
                </div>

            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default BlogDetailsPage;
