import React from 'react';
import WidgetPost from './widget/WidgetPost';
import WidgetCategory from './widget/WidgetCategory';

const BlogSidebar = ({categoryId}) => {
    return (
        <div className="axil-sidebar">

            <div className="widget widget-categories">
                <h4 className="widget-title">블로그</h4>
                <WidgetCategory />
            </div>
            {/*<div className="widget widge-social-share">*/}
            {/*    <div className="blog-share">*/}
            {/*        <h5 className="title">Follow:</h5>*/}
            {/*        <ul className="social-list list-unstyled">*/}
            {/*            <li><a href="https://facebook.com/"><FaFacebookF /></a></li>*/}
            {/*            <li><a href="https://twitter.com/"><FaTwitter /></a></li>*/}
            {/*            <li><a href="https://www.instagram.com/"><FaInstagram /></a></li>*/}
            {/*            <li><a href="https://www.linkedin.com/"><FaLinkedinIn /></a></li>*/}
            {/*            <li><a href="https://www.youtube.com/"><FaYoutube /></a></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="widget widget-recent-post">
                <h4 className="widget-title">Recent post</h4>
                <WidgetPost categoryId={categoryId} />
            </div>

        </div>
    )
}

export default BlogSidebar;
