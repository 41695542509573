import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import

// Blog Import

// Service

// Project

// Pages
import PrivacyPolicy from './pages/policy/PrivacyPolicy';
import TermsOfUse from './pages/policy/TermsOfUse';

// Css Import
import './assets/scss/app.scss';
import RegionBasedResearch from "./pages/service/RegionBasedResearch";
import MarketingResearch from "./pages/service/MarketingResearch";
import ElectronicVoting from "./pages/service/ElectronicVoting";
import TimeToThrowAway from "./pages/service/TimeToThrowAway";
import SurveyPlatform from "./pages/product/SurveyPlatform";
import VotingPlatform from "./pages/product/VotingPlatform";
import ResultAnalysisPlatform from "./pages/product/ResultAnalysisPlatform";
import TimeToThrowAwayPlatform from "./pages/product/TimeToThrowAwayPlatform";
import BlockchainBaaSService from "./pages/product/BlockchainBaaSService";
import KonaChainNews from "./pages/blog/KonaChainNews";
import ResearchTips from "./pages/blog/ResearchTips";
import KonaChainArticle from "./pages/blog/KonaChainArticle";
import TrendReport from "./pages/blog/TrendReport";
import VotingServiceDocuments from "./pages/documents/VotingServiceDocuments";
import CustomerContact from "./pages/contact/CustomerContact";
import HomePage from "./pages/home/HomePage";
import BaaSResearch from "./pages/service/BaaSResearch";
import KonaChainNewsDetails from "./pages/blog/KonaChainNewsDetails";
import BlogDetailsPage from "./pages/blog/BlogDetailsPage";
import ErrorPage from "./pages/404";


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<HomePage />}/>


				{/* KONA CHAIN SERVICE */}
				<Route path={process.env.PUBLIC_URL + "/region-based-research"} element={<RegionBasedResearch />}/>
				<Route path={process.env.PUBLIC_URL + "/marketing-research"} element={<MarketingResearch />}/>
				<Route path={process.env.PUBLIC_URL + "/electronic-voting"} element={<ElectronicVoting />}/>
				<Route path={process.env.PUBLIC_URL + "/time-to-throw-away"} element={<TimeToThrowAway />}/>
				<Route path={process.env.PUBLIC_URL + "/blockchain-baas-service"} element={<BaaSResearch />}/>

				{/* KONA CHAIN PRODUCT */}
				<Route path={process.env.PUBLIC_URL + "/survey-platform"} element={<SurveyPlatform />}/>
				<Route path={process.env.PUBLIC_URL + "/voting-platform"} element={<VotingPlatform />}/>
				<Route path={process.env.PUBLIC_URL + "/result-analysis-platform"} element={<ResultAnalysisPlatform />}/>
				<Route path={process.env.PUBLIC_URL + "/time-to-throw-away-platform"} element={<TimeToThrowAwayPlatform />}/>
				<Route path={process.env.PUBLIC_URL + "/blockchain-baas-pt"} element={<BlockchainBaaSService />}/>

				{/* KONA CHAIN Blogs */}
				<Route path={process.env.PUBLIC_URL + "/kona-chain-news"} element={<KonaChainNews />}/>
				<Route path={process.env.PUBLIC_URL + "/kona-chain-news-details/:id"} element={<KonaChainNewsDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/trend-report"} element={<TrendReport />}/>
				<Route path={process.env.PUBLIC_URL + "/blog-details/:blogId"} element={<BlogDetailsPage />}/>
				<Route path={process.env.PUBLIC_URL + "/kona-chain-article"} element={<KonaChainArticle />}/>
				<Route path={process.env.PUBLIC_URL + "/research-tips"} element={<ResearchTips />}/>

				{/*KONA SERVICE DOCUMENTATION */}
				<Route path={process.env.PUBLIC_URL + "/voting-service-documents"} element={<VotingServiceDocuments />}/>

				{/*KONA SERVICE DOCUMENTATION */}
				<Route path={process.env.PUBLIC_URL + "/inquiry"} element={<CustomerContact />}/>
				<Route path={"/*"} element={<ErrorPage/>}/>

				<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}/>
				<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />}/>

			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
