import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import MarketBasedResearchData from "../../data/service/MarketBasedResearchData.json";
import {slugify} from '../../utils';
import RegionBasedResearchProp from "../../component/service/RegionBasedResearchProp";
import InquirySection from "../../component/homepage/InquirySection";
import KcSectionTitle from "../../elements/section-title/KcSectionTitle";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";
import KonaCardDataTable from "../../component/service/KonaCardDataTable";
import QuotesArea from "../../component/service/QuotesArea";
import {Link} from "react-router-dom";

const marketBasedResearchData = MarketBasedResearchData;


const MarketingResearch = () => {
    const operationalData = marketBasedResearchData.filter(data => slugify(data.cate ? data.cate : "") === "operational");
    const industryData = marketBasedResearchData.filter(data => slugify(data.cate ? data.cate : "") === "industry");
    const publicResearch = marketBasedResearchData.filter(data => slugify(data.cate ? data.cate : "") === "public_research");

    const quoteTextList = [
        "이 조사결과가 믿을 수 있나요?"
        , "전국대상으로 설문조사를 할 수 있나요?"
        , "빅데이터와 융합된 결과를 얻고 싶어요."
    ]

    return (
        <>
            <SEO title="코나체인 | 기업 리서치"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />

            <BannerHomePageThree
                backgroundImageUrl = "/images/banner/marketing-research-banner.jpg"
                bannerClassName="kc-min-height--600 kc-pt--210"
                titleText = "기업 리서치"
                subTitleText = "To know list를 공유해 주세요. <br/>해결방안을 제안해 드립니다."
            />

            <QuotesArea
                quotesTitle = "신뢰성있는 설문조사를 하고 싶나요? <br/> 코나체인의 빅데이터와 함께하세요."
                quotesImageUrl = "/images/service/quotes_marketing-research.svg"
            />

            <div className="service-scroll-navigation-area bg-color-light kc-pt--90 pb--90">
                    <div className="container">
                        <div className="row g-0">
                            <div>
                                <h2 className="kc-title">고객의 사업 성장을 위한 파트너,
                                    <br/>코나체인이 쉽고 간편한 데이터 수집을 지원 하겠습니다
                                </h2>
                                <p style={{fontSize: "var(--font-body-1)"}}>신뢰성과 대표성 및 빅데이터 연계성을 갖춘 코나체인의 전국패널 Pool 코나카드 회원  대상으로 설문조사를 진행하세요</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <KonaCardDataTable/>
                                </div>
                                <div className="col-lg-6  order-lg-1" style={{textAlign: "right"}}>
                                    <img src="/images/service/image-1.1.png" width={"75%"} style={{paddingTop: "40px"}} alt="Case Study"/>
                                </div>
                            </div>

                        </div>

                    </div>
            </div>

            <div className="service-scroll-navigation-area kc-pt--90 pb--90">
                <div className="container">
                    <div className={`section-heading`}>
                        <h2 className="kc-title">코나체인만의 다이나믹 데이터 분석으로<br/>극사실 예측으로 비즈니스를 성공으로 이끕니다.</h2>
                    </div>
                    <div className="kc-center-div-p-90 pt--40">
                        <img src="/images/service/image-1.3.png" width={"80%"}/>
                    </div>
                    <div className="kc-pt--90"></div>
                    <div className={`section-heading`}>
                        <h2 className="kc-title">비즈니스 분석부터 홍보 광고까지 코나체인과 함께!</h2>
                    </div>
                    <div className="kc-center-div-p-90 pt--40">
                        <img src="/images/service/image-1.4.png" width={"80%"}/>
                    </div>
                </div>
            </div>

            <div className="service-scroll-navigation-area kc-pt--90">

                <nav id="onepagenav" className="service-scroll-nav navbar onepagefixed">
                    <div className="container">
                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <a className="nav-link" href="#section1">공공 리서치</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#section2">마케팅 리서치</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#section3">기업/산업 리서치</a>
                            </li>
                        </ul>
                    </div>
                </nav>


                    <div className="section" id="section1">
                        <div className="container">
                            <KcSectionTitle
                                subtitle=""
                                title="공공 리서치"
                                description="제품 및 서비스 사용행태(U&A) 조사, 브랜드 인식 조사, 신제품 컨셉 테스트 등 <br/>마케팅 의사결정을 위한 기초 자료를 코나체인과 함께 수집하세요. "
                                textAlignment="heading-left"
                                textColor=""

                            />
                            <div className="row">
                                <RegionBasedResearchProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2"
                                             serviceData={publicResearch}/>
                            </div>
                        </div>
                    </div>

                <div className="section mt--80" id="section2">
                    <div className="container">
                        <KcSectionTitle
                            subtitle=""
                            title="마케팅 리서치"
                            description="제품 및 서비스 사용행태(U&A) 조사, 브랜드 인식 조사, 신제품 컨셉 테스트 등 <br/>마케팅 의사결정을 위한 기초 자료를 코나체인과 함께 수집하세요. "
                            textAlignment="heading-left"
                            textColor=""
                        />
                        <div className="row">
                            <RegionBasedResearchProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={operationalData}/>
                        </div>
                    </div>
                </div>

                <div className="section mt--80" id="section3">
                    <div className="container">
                        <KcSectionTitle
                            subtitle=""
                            title="기업/산업 리서치"
                            description="제품 및 서비스 사용행태(U&A) 조사, 브랜드 인식 조사, 신제품 컨셉 테스트 등 <br/>마케팅 의사결정을 위한 기초 자료를 코나체인과 함께 수집하세요. "
                            textAlignment="heading-left"
                            textColor=""
                        />
                        <div className="row">
                            <RegionBasedResearchProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={industryData}/>
                        </div>
                    </div>
                </div>

            </div>
            <InquirySection/>
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default MarketingResearch;
