import React from 'react';


const ElectronicVotingServiceHeader = () => {
    return (
        <>
            <div className="service-scroll-navigation-area kc-pt--90">
                <div className="container">
                    <div className="row g-0">
                        <h2 className="kc-title">주민이 행사한 소중한 한표!
                            <br/>코나체인 투표서비스가 익명을 보장하며,
                            <br/>신뢰할 수 있는 서비스 환경을 제공합니다.
                        </h2>
                        <div className="row">
                            <div className="col-lg-6 pb_md--70">
                                <img width={"80%"} src="/images/service/voting-service-image-1.1.png" alt="Case Study"/>
                            </div>
                            <div className="col-lg-6 order-lg-1">
                                <div>
                                    <p>신뢰있는 투표서비스로 지역사회 주민들의 <span className="kc-highlight-yellow">익명성</span>과 <span
                                        className="kc-highlight-yellow">무결성</span>이 동시에 보장됩니다</p>
                                    <br/>
                                    <img src="/images/service/electronic-voting-image-2.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 mt--80">
                        <h2 className="kc-title">일상생활 속의 투표!
                            <br/>코나체인이 쉽고 간편하게 주민의 한표를 수집하도록 지원 하겠습니다!
                        </h2>
                        <p>코나체인의 투표서비스는 쉽고 간편하여 다양한 분야에서
                            <br/>투표를 진행할 수 있습니다.
                        </p>
                        <img src="/images/service/electronic-voting-image-1.png"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ElectronicVotingServiceHeader;
