import React from 'react';


const RegionBaseResHeader = () => {
    return (
        <>
            <div className="service-scroll-navigation-area kc-pt--90">
                <div className="container">
                    <div className="row g-0">
                        <h2 className="kc-title">코나체인 설문조사가 여러분을 대신하여 목소리를
                            <br/>많이! 빠르게! 듣겠습니다
                        </h2>
                        <div className="row">
                            <div className="col-lg-6">
                                <div>
                                    <p>간편한 설문 진행 절차로 지역사회 주민들의 <br/><span
                                        className="kc-highlight-yellow">높은 참여율</span>과 <span
                                        className="kc-highlight-yellow">빠른 설문 진행</span>이 동시에 보장됩니다</p>
                                    <br/>
                                    <br/>
                                    <img src="/images/service/reginon-base-research-image-4.png" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-6  order-lg-1" style={{textAlign: "right"}}>
                                <img src="/images/service/region-b-image-1.1.png" width={"100%"} alt=""/>
                            </div>
                        </div>
                    </div>

                    <div className="row g-0">
                        <h2 className="kc-title mt--80">지역조사 사례</h2>
                        <img src={process.env.PUBLIC_URL + "/images/service/region-based-research-image-1.png"}
                             alt="bubble"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegionBaseResHeader;
