import React from 'react';
import {FaDotCircle} from "react-icons/fa";


const SectionTwoTableB = () => {
    return (
        <>

            <table className="table table-bordered" >
                <thead>
                <tr className="table-light align-middle">
                    <th scope="col" style={{width: "25%"}}></th>
                    <th scope="col" style={{width: "15%"}}>1. 전혀 그렇지 않다</th>
                    <th scope="col" style={{width: "15%"}}>2. 그렇지 않다</th>
                    <th scope="col" style={{width: "15%"}}>3. 보통이다</th>
                    <th scope="col" style={{width: "15%"}}>4. 그렇다</th>
                    <th scope="col" style={{width: "15%"}}>5. 매우 그렇다</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="align-middle">1-1. 업계를 선도하는</td>
                    <td className="align-middle" align={"center"}>1</td>
                    <td className="align-middle" align={"center"}>2</td>
                    <td className="align-middle" align={"center"}>3</td>
                    <td className="align-middle" align={"center"}>4</td>
                    <td className="align-middle" align={"center"}>5</td>
                </tr>
                <tr>
                    <td className="align-middle">1-2. 차별적인</td>
                    <td className="align-middle" align={"center"}>1</td>
                    <td className="align-middle" align={"center"}>2</td>
                    <td className="align-middle" align={"center"}>3</td>
                    <td className="align-middle" align={"center"}>4</td>
                    <td className="align-middle" align={"center"}>5</td>
                </tr>
                <tr>
                    <td className="align-middle">1-3. 혁신적인</td>
                    <td className="align-middle" align={"center"}>1</td>
                    <td className="align-middle" align={"center"}>2</td>
                    <td className="align-middle" align={"center"}>3</td>
                    <td className="align-middle" align={"center"}>4</td>
                    <td className="align-middle" align={"center"}>5</td>
                </tr>
                </tbody>
            </table>

        </>
    )
}

export default SectionTwoTableB;
