import React from 'react';


const ContactHeader = () => {
    return (
        <>
                <div className="container">

                    <div className="row g-0">

                        <h4>문의 팁</h4>
                            <div className="col-md-8 col-sm-6 mb--30">
                                <ol className="list-style">
                                    <li>의뢰하신 분의 회사명, 부서명, 직함과 성명, 이메일주소, 전화번호를 입력하셔야 회신을 드릴 수 있습니다.</li>
                                    <li>아래 조사 관련 문의사항을 아시는 만큼만 입력해 주시면 해당 분야 조사 전문가가 회신 드리겠습니다.</li>
                                </ol>
                            </div>

                        <h4>조사 관련 문의 사항</h4>
                        <div className="col-md-8 col-sm-6 mb--30">
                            <ol className="list-style">
                                <li>왜 이 조사를 하고자 하는지</li>
                                <li>이 조사를 수행하여 어디에 이용하고자 하는지</li>
                                <li>알아야 할 사항은 무엇이 있는지</li>
                                <li>누구를 대상으로 조사하고자 하는지</li>
                                <li>조사 시기는 언제로 예상하는지</li>
                                <li>조사 결과물이 언제까지 나오길 원하는지</li>
                                <li>예산은 어느 정도인지</li>
                                <li>원자료, 자료처리결과, 보고서, software중 어떤 것을 제공받기 원하는지</li>
                            </ol>
                        </div>



                    </div>
                </div>
                <ul className="shape-group-1 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                                                       alt="bubble"/></li>
                    {/*<li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"}*/}
                    {/*                                   alt="bubble"/></li>*/}
                    {/*<li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-2.png"}*/}
                    {/*                                   alt="bubble"/></li>*/}
                    {/*<li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}*/}
                    {/*                                   alt="bubble"/></li>*/}
                </ul>

        </>
    )
}

export default ContactHeader;
