import React from 'react';
import LineDraw from "./LineDraw";
import PlatformPanelDataTable from "../../component/product/PlatformPanelDataTable";


const SectionThree = () => {
    return (
        <>
            <section id="section-3"><h3>3. 설문 관리 </h3>
            </section>

            <section id="section-3-1"><h4>3.1 설문조사 결과 확인</h4>
                <p>설문조사 생성자는 설문조사가 종료되면, 설문조사 결과를 확인 할 수 있습니다.
                    <br/>“My CHAIN”에서 내가 등록한 설문조사 목록을 통해 생성된 설문조사에 접근하면 아래와 같이 확인할 수 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/3.1_1.png"} alt="설문조사 결과 확인"/>
                <img src={process.env.PUBLIC_URL + "/images/document/3.1_2.png"} alt="설문조사 결과 확인"/>
                <p>또한 생성자는 화면 최하단의 “설문조사 결과 다운로드” 버튼을 선택하여 결과파일을 다운로드 받을 수 있습니다.
                    <br/>결과파일은 아래와 같이 구성되어 있습니다.
                </p>
                <img src={process.env.PUBLIC_URL + "/images/document/3.1_3.png"} alt="설문조사 결과 확인"/>
                <p>설문조사의 상세한 데이터 또는 보고서 등의 추가적인 요청은 서비스 문의로 관리자에게 요청하세요.</p>
            </section>
        </>
    )
}

export default SectionThree;
