import React, {useCallback, useRef, useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import {inquiryApi, storageApi} from "../../api";
import {Constants} from "../../helpers";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import AttachmentListGenerate from "../blog/AttachmentListGenerate";
import {useNavigate} from "react-router-dom";

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            서비스 문의 내용이 정상적으로 접수되었습니다.
        </Alert>
    )
}

export const InquiryDataDefault = {
    token: "",
    companyName: "",
    department: "",
    name: "",
    companyPhoneNumber: "",
    companyEmail: "",
    contactMessage: "",
    attachmentList:[],
    tcagreement:false
}

const FormTwo = () => {
    const navigate = useNavigate()
    const form = useRef();

    const [inquiryData, setInquiryData] = useState(InquiryDataDefault);

    const handleChange = (name, value) => {
        console.log("name => {} = {}", name, value);
        console.log("All inquiryData=> {}", inquiryData);

        if(name === 'tcagreement'){
            if(value.checked){
                value = true;
            } else {
                value = false;
            }
        }

        setInquiryData(prevState => {
            return {
                ...prevState
                , [name]: value
            }
        });
    }

    const [result, showResult] = useState(false);

    const validation = () => {

        if(!inquiryData.companyName){
            window.alert("Company Name is required")
            return false;
        }
        if(!inquiryData.department){
            window.alert("Department is required")
            return false;
        }
        if(!inquiryData.name){
            window.alert("Name is required")
            return false;
        }
        if(!inquiryData.companyPhoneNumber){
            window.alert("Company Phone Number is required")
            return false;
        }
        if(!inquiryData.companyEmail){
            window.alert("Company Email is required")
            return false;
        }

        if(!inquiryData.contactMessage){
            window.alert("문의내용을 작성 해 주세요.")
            return false;
        }

        if(!inquiryData.tcagreement){
            window.alert("Please checked T&C agreement.")
            return false;
        }

        return true;
    }


    const submitInquireDataToServer  = () => {

        console.log('--------------------------------------------------------------------')
        console.log(inquiryData);
        console.log('--------------------------------------------------------------------')

        if(!validation()){
            return;
        }

        const payload = {
            token: inquiryData.token,
            companyName: inquiryData.companyName,
            department: inquiryData.department,
            name: inquiryData.name,
            companyPhoneNumber: inquiryData.companyPhoneNumber,
            companyEmail: inquiryData.companyEmail,
            contactMessage: inquiryData.contactMessage,
            attachmentFileName: inquiryData.attachmentList
        }

        inquiryApi.inquirySubmit(payload).then(res => {
            console.log("inquiry response", res)
            form.current.reset();
            setInquiryData(InquiryDataDefault);
            showResult(true);
            setToken('');
            window.alert("서비스 문의 내용이 정상적으로 접수되었습니다.");
            navigate("/");
        }).catch(err => {
            console.log("Inquiry Error", err)
        })
    }

    setTimeout(() => {
        showResult(false);
    }, 5000);


    // const recaptchaVerifyingAndSubmitData = () => {
    //     const payload = {
    //         token: token
    //     }
    //     inquiryApi.verifyRecaptcha(payload).then(res => {
    //         console.log("verifyRecaptcha response data", res.data)
    //         if(res.data){
    //             console.log("verifyRecaptcha success, sending data to server...")
    //             submitInquireDataToServer();
    //         } else {
    //             console.log("verifyRecaptcha failed")
    //             window.alert("Request verification failed, reload the page and try again.")
    //             return false;
    //         }
    //     }).catch(err => {
    //         console.log("verifyRecaptcha Error", err)
    //         window.alert("Request verification failed, reload the page and try again.")
    //         return false;
    //     })
    // }

    // Google reCaptcha ----------------------------------------------------------------
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');

    const submitInquiryData = useCallback(async (event) => {
        console.log("submitInquiryData");
        event.preventDefault();

        if (!executeRecaptcha) {
            window.alert("Please reload the page.")
            return;
        }
        const result = await executeRecaptcha('inquiry_submit');
        setToken(result);
        let currentInquiryData = inquiryData;
        currentInquiryData.token = result;
        submitInquireDataToServer();
    }, [executeRecaptcha, inquiryData]);


    // Google reCaptcha ----------------------------------------------------------------


    //File upload section ----------------------------------------------------------------
    const [attachmentList, setAttachmentList] = useState([
    ]);
    const uploadFile = (event) => {
        let target = event.target
        if (target.files.length > 0) {
            let file = target.files[0];
            console.log("Uploading file........")
            storageApi.uploadFile(file).then(result => {
                console.log("upload successful")
                handleFileUploadList(result.data.fileUrl);
            }).catch(err => {
                console.log("Could not upload banner image.")
                window.alert(err)
            })
            console.log("upload complete")
        }
    }

    const handleFileUploadList = (fileUrl) => {
        let currentFiles = attachmentList;
        currentFiles.push(fileUrl)
        console.log("currentFiles => ");
        console.log(currentFiles);
        setAttachmentList(currentFiles);
        setInquiryData(prevState => {
            return {
                ...prevState
                , "attachmentList": currentFiles
            }
        });
    }

    const onDeleteHandler = (fileName) => {
        let currentFiles = attachmentList;
        let updatedFileList = currentFiles.filter(function(e) { return e !== fileName })
        setAttachmentList(updatedFileList);
        setInquiryData(prevState => {
            return {
                ...prevState
                , "attachmentList": updatedFileList
            }
        });
    }
    // <<----------------------------------------------------------------

    return (
        <>
        <form ref={form} className="axil-contact-form">
            <div className="form-group">
                <label>회사명<span className="text-danger">*</span></label>
                <input type="text" className="form-control" name="companyName" onChange={event => handleChange("companyName", event.target.value)}/>
            </div>
            <div className="form-group">
                <label>부서/직급<span className="text-danger">*</span></label>
                <input type="text" className="form-control" name="department" onChange={event => handleChange("department", event.target.value)}/>
            </div>
            <div className="form-group">
                <label>성명<span className="text-danger">*</span></label>
                <input type="text" className="form-control" name="name" onChange={event => handleChange("name", event.target.value)}/>
            </div>
            <div className="form-group">
                <label>회사연락처<span className="text-danger">*</span></label>
                <input type="tel" className="form-control" name="companyPhoneNumber" onChange={event => handleChange("companyPhoneNumber", event.target.value)}/>
            </div>
            <div className="form-group">
                <label>회사 E-mail<span className="text-danger">*</span></label>
                <input type="email" className="form-control" name="companyEmail" onChange={event => handleChange("companyEmail", event.target.value)}/>
            </div>
            <div className="form-group">
                <label>첨부파일</label>

                <input id="inputGroupFile02" className="form-control" type="file"
                       value={""}
                       accept={Constants.UPLOAD_PRODUCT_FILE_ALLOWED_TYPE}
                       onChange={(e) => uploadFile(e)}
                       placeholder="Select File"/>

                <AttachmentListGenerate
                    isEdit
                    attachmentObject={attachmentList}
                    onDeleteHandler = {onDeleteHandler}
                />

                <p style={{fontSize : "12px", margin : "5px 0 0 5px"}}>설문지, 조사계획서 등을 첨부해주시면 더욱 정확한 안내를 드리겠습니다.<br/>
                    파일 확장자는 .doc, .docx, .hwp 형식이 가능하며 최대 10 MB 가능합니다.</p>
            </div>

            <div className="form-group mb--40">
                <label>문의내용<span className="text-danger">*</span></label>
                <textarea className="form-control" name="contactMessage" rows="3" onChange={event => handleChange("contactMessage", event.target.value)}/>
            </div>
            <div className="form-group mb--40">
                <div style={{width : "20px", float: "left", height: "20px", marginRight: "10px"}}>
                    <input type="checkbox" name="tcagreement" style={{height: "20px"}} onChange={event => handleChange("tcagreement", event.target)}/>
                </div>
                <div>동의합니다</div>
            </div>
            <div className="form-group">
                <button type="submit" onClick={submitInquiryData} className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">문의하기
                </button>
            </div>
            <div className="form-group">
                {result ? <Result/> : null}
            </div>

        </form>

            </>
    )
}

export default FormTwo;
