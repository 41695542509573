import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import HomePageASPKAList from "../../component/homepage/HomePageASPKAList";
import HomePageServiceInfo from "../../component/homepage/HomePageServiceInfo";
import HomePageBlogSection from "../../component/homepage/HomePageBlogSection";
import HomePageBusinessInfoSection from "../../component/homepage/HomePageBusinessInfoSection";
import InquirySection from "../../component/homepage/InquirySection";
import BannerHomePageTwo from "../../component/banner/BannerHomePageTwo";
import HomePageServiceInfoTop from "../../component/homepage/HomePageServiceInfoTop";
import { Helmet } from "react-helmet"


const DigitalAgency = () => {

    return (
        <>
            {/* SEO */}
            <Helmet>
                <link rel="canonical" href="https://konachain.com" />
            </Helmet>

            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BannerHomePageTwo
                    backgroundImageUrl = "/images/banner/home-banner.jpg"
                    topSubTitleText = "설문조사&투표 서비스의 패러다임의 변화"
                    titleText = "기업 홍보 및 제품 조사에 필요한 <br/>최적의 설문 플랫폼과 패널을 제공합니다.<br/><br/>"
                    button1 = "지금 문의하기"
                    button2 = "소개서 받기"
                    bottomSubTitleText = "코나체인이 비즈니스 성공에 길잡이가 되겠습니다."
                    rightImageUrl = "/images/banner/phone_image.png"
                />
                <HomePageServiceInfoTop/>
                <HomePageServiceInfo/>
                {/*<BannerHomePage/>*/}
                <HomePageBlogSection/>
                <HomePageASPKAList/>
                <HomePageBusinessInfoSection/>
                <InquirySection/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default DigitalAgency;

