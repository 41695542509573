import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import InquirySection from "../../component/homepage/InquirySection";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";


const TimeToThrowAwayPlatform = () => {

    return (
        <>
            <SEO title="코나체인 | 환경 종합 플랫폼"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>

                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/time-to-throw-away-platform-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText="인센티브 지급형<br/>환경 종합 플랫폼"
                    subTitleText="인센티브 지급, 환경 종합 정보 제공, 다양한 참여 콘텐츠 제공<br/>인센티브를 지급하여 <br/>시민들의 적극적인 참여를 격려합니다.<br/>인센티브 지급형 환경 종합 플랫폼을 이용 해 보세요!"
                />

                <div className="section service-scroll-navigation-area kc-pt--90">
                    <div className="container">
                        <div className={`section-heading`}>
                            <h2 className="kc-title">적극적인 참여 유도</h2>
                        </div>
                        <div className="row">
                            <img src={process.env.PUBLIC_URL + "/images/platform/kttt-image-1.1.png"}
                                 alt="bubble"/>

                        </div>
                    </div>
                </div>

                <div className="section service-scroll-navigation-area kc-pt--90">
                    <div className="container">
                        <div className={`section-heading`}>
                            <h2 className="kc-title">OCR 기술 적용</h2>
                            <p>AI가 적용된 OCR 기술로 오프라인 활동을 확인하며,
                                <br/>인센티브를 즉시 받을 수 있습니다.
                            </p>
                        </div>
                        <img src={process.env.PUBLIC_URL + "/images/platform/kttt-platform-image-2.png"}
                             alt="bubble"/>
                    </div>
                </div>

                <InquirySection/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default TimeToThrowAwayPlatform;
