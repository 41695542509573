import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SurveyPanelData from "../../data/platform/SurveyPanelData.json";
import SurveyPlatformProp from "../../component/product/SurveyPlatformProp";
import InquirySection from "../../component/homepage/InquirySection";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";

const surveyPanelData = SurveyPanelData;

const SurveyPlatform = () => {
    return (
        <>
            <SEO title="코나체인 | 설문조사 플랫폼"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>

                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/survey-platform-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText="설문조사 플랫폼"
                    subTitleText="전문가가 아니라도 괜찮습니다.  알고 싶은 궁금증만 가져오세요.<br/><br/>누구나 쉽게! 빠르게!<br/>설문을 만들고 결과를 분석하세요!"
                />


                <div className="section service-scroll-navigation-area kc-pt--90">
                    <div className="container">
                        <div className={`section-heading`}>
                            <h2 className="kc-title">간편한 설문조사 진행 절차</h2>
                        </div>
                        <img src={process.env.PUBLIC_URL + "/images/platform/survey-platform-image-1.png"}/>
                    </div>
                </div>

                <div className="section service-scroll-navigation-area kc-pt--90 pb--90">
                    <div className="container">
                        <div className="section-heading">
                            <h2 className="kc-title">블록체인 기술 적용</h2>
                            <p style={{width: "100%"}}>설문 조사 결과는 위변조가 불가능하며, 투명성이 보장될 수 있습니다</p>
                        </div>
                        <div className="kc-center-div-p-90">
                            <img src={process.env.PUBLIC_URL + "/images/platform/survey-platform-image-3.png"}
                                 alt="bubble"/>
                        </div>
                    </div>
                </div>

                <div className="section service-scroll-navigation-area  bg-color-light kc-pt--90 pb--60">
                    <div className="container">
                        <div className="row">
                            <SurveyPlatformProp colSize="col-lg-2 col-md-6" serviceStyle="service-style-2"
                                                serviceData={surveyPanelData}/>
                        </div>
                    </div>
                </div>

                <InquirySection/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default SurveyPlatform;
