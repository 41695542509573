import React from 'react';


const LineDraw = () => {
    return (
        <>
            <div style={{borderTop: "1px solid grey", paddingBottom: "10px"}}></div>
        </>
    )
}

export default LineDraw;
