import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import {FaFileDownload} from "react-icons/fa";
import {blogApi} from "../../api/blogApi";
import {DateUtil} from "../../helpers/DateUtil";
import PaginationUtil from "../../helpers/PaginationUtil";
import {CommonUtil} from "../../helpers";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";


const KonaChainNews = () => {

    let count = 1;

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        let _pageNumber =  event.selected;
        setSearchParams(prevState => {
            return {
                ...prevState,
                pageNumber: _pageNumber
            }
        })
        setSearchOnChange(true);
        console.log(
            `User requested page number ${event.selected}`
        );
    };

    const [searchOnChange, setSearchOnChange] = useState(false);
    const [searchParams, setSearchParams] = useState({
        pageSize : 10
        , pageNumber : 0
        , categoryId:1
    });

    const [listInfo, setListInfo] = useState({
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
    });

    useEffect(() => {
        handleSearch();
    }, [])

    useEffect(() => {
        if(searchOnChange) {
            handleSearch();
            setSearchOnChange(false);
        }
    }, [searchOnChange])

    const handleSearch = () => {
        console.log("calling handleSearch ... ", {searchParams});
        blogApi.getBlogList(searchParams)
            .then(res => {
                    setListInfo(prevState => {
                        return {
                            ...prevState,
                            ...res.data
                        }
                    });
                    count = res.data.totalElements;
                }
            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };


    return (
        <>
            <SEO title="코나체인 | 소식"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600"
                    titleText="코나체인 소식"
                />

                <div className="section-padding-equal">
                    <div className="container">
                        <div className="row">

                            <span>Total: <span style={{color: "red", fontWeight: "bold"}}>{listInfo.totalElements}</span></span>
                            <table className="table">
                                <thead>
                                <tr className={"table-light align-middle"} align={"center"}>
                                    <th scope="col" style={{width: "5%"}}>번호</th>
                                    <th scope="col" style={{width: "70%"}}>제목</th>
                                    <th scope="col" style={{width: "10%"}}>첨부</th>
                                    <th scope="col" style={{width: "15%"}}>작성일</th>
                                </tr>
                                </thead>
                                <tbody>

                                {listInfo.content.map((blog, i) => (

                                    <tr>
                                        <td>{CommonUtil.getRowId(
                                            i,
                                            listInfo.number,
                                            listInfo.size,
                                            listInfo.totalElements,
                                            'DESC'
                                        )}</td>
                                        <td>
                                            <Link className={"kc-text-color-black kc-p-margin-zero"}
                                                  to={process.env.PUBLIC_URL + "/kona-chain-news-details/" + blog.id} dangerouslySetInnerHTML={{__html: blog.title}}>
                                            </Link>
                                        </td>
                                        <td align={"center"}>
                                            {blog.hasAttachment &&
                                            <FaFileDownload/>
                                            }
                                            {!blog.hasAttachment &&
                                            <span>-</span>
                                            }
                                        </td>
                                        <td align={"center"}>{DateUtil.format(blog.postDate)}</td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>

                            <PaginationUtil
                                handlePageClick={handlePageClick}
                                totalPages={listInfo.totalPages}
                            />
                        </div>
                    </div>
                </div>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default KonaChainNews;
