import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import InquirySection from "../../component/homepage/InquirySection";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";
import QuotesArea from "../../component/service/QuotesArea";
import {Link} from "react-router-dom";


const BaaSResearch = () => {
    const quoteTextList = [
        "블록체인으로 새로운 서비스를 제공하고 싶어요."
        , "블록체인을 적용하면 무엇이 좋은거죠?"
        , "블록체인을 어떻게 적용해야 할 지 모르겠어요."
    ]

    return (
        <>
            <SEO title="코나체인 | 블록체인 BaaS"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>

                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/service-bg-6.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--210"
                    titleText="블록체인 BaaS"
                    subTitleText="어렵고 복잡한 블록체인 비즈니스!<br/>코나체인이 도와드리겠습니다."
                />

                <QuotesArea
                    bgClassName="bg-color-light"
                    quotesTitle="데이터 수집과 관리<br/>코나체인을 만나보세요."
                    quotesImageUrl="/images/service/quotes_blockchain-baas-service.svg"
                />

                <div className="service-scroll-navigation-area kc-pt--90 pb--90">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-6 pb_md--70">
                                <img src={process.env.PUBLIC_URL + "/images/service/BaaS-service-image-1.png"}
                                     alt="bubble"/>
                            </div>
                            <div className="col-lg-6 order-lg-1">
                                <h4>코나체인은 하이퍼레저 패브릭 기반으로<br/>
                                    기업들의 다양한 요구사항에 <br/>유연하게 대응할 수 있는
                                    <br/>엔터프라이즈 블록체인 플랫폼 솔루션입니다.</h4>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="section section-padding bg-color-dark  kc-pt--90 pb--90"
                     style={{backgroundColor: "#191D32", padding: "0px"}} id="section2">
                    <div className="container">
                        <img src={process.env.PUBLIC_URL + "/images/service/BaaS-service-image-2.png"}
                             alt="bubble"/>
                    </div>
                </div>

                <div className="section kc-pt--90">
                    <div className="container">
                        <div className={`section-heading`}>
                            <h4 className="kc-title">코나체인은 다양한 형태로 서비스를 제공하여,<br/>
                                만족스러운 블록체인기반의 서비스를 제공합니다.</h4>
                        </div>
                        <img src={process.env.PUBLIC_URL + "/images/service/BaaS-service-image-3_new.png"}
                             alt="bubble"/>
                        <div className="mt--100"></div>
                        <img src={process.env.PUBLIC_URL + "/images/service/Bass-service-image-4_new.png"}
                             alt="bubble"/>
                    </div>
                </div>


                <InquirySection/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default BaaSResearch;
