import React from 'react';
import {useNavigate} from "react-router-dom"

const TagPreview = ({tagList}) => {
    const navigate = useNavigate()
    const handleClick = (tagId) => {
        console.log("Tag ID: " + tagId);
        // navigate("/inquiry")
    }

    return (
        <div className="container">
            {tagList.map((tag) => (
                <button style={{marginRight: "5px"}} onClick={data => handleClick(data.target.value)} value={tag.id} className="btn btn-outline-info">#{tag.name}</button>
            ))
            }
        </div>
    )
}

export default TagPreview;