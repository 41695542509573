import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import InquirySection from "../../component/homepage/InquirySection";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";


const BlockchainBaaSService = () => {

    return (
        <>
            <SEO title="코나체인 | 블록체인 BaaS  플랫폼"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/blockchain-baas-pt-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--210"
                    subTitleText="어렵고 복잡한 블록체인 비즈니스!<br/>코나체인이 도와드리겠습니다."
                    titleText="블록체인 BaaS  플랫폼"
                />

                <div className="section service-scroll-navigation-area kc-pt--90">
                    <div className="container">

                        <div className={`section-heading`}>
                            <h3 className="kc-title">블록체인 네트워크 구축 및 운영을 지원하는<br/>
                                서비스형 블록체인(BaaS) 서비스입니다.
                            </h3>
                            <p style={{width: "100%"}}>
                                코나체인 BaaS 서비스는 리눅스재단(The Linux Foundation)의 하이퍼레저 패브릭(Hyperledger Fabric)을<br/>
                                기반으로 고객이 원하는 블록체인 기능을 쉽고 빠르게 적용할 수 있는 플랫폼을 제공합니다.<br/>
                                <br/>
                                클라우드 기반 서비스 형 블록체인 플랫폼으로 블록체인을 위한 초기 노드 구축비용이 부담되거나,<br/>
                                스마트 컨트랙트 개발에 많은 시간을 할애하고 있는 경우, 개발한 플랫폼의 대외 신뢰도가 부족한 경우,<br/>
                                코나체인 플랫폼(BaaS)서비스 도입으로 이와 같은 이슈를 해결 할 수 있습니다.<br/>
                            </p>
                        </div>

                        <div className="row row-45">
                            <div className="col-md-4">
                                <div className={`project-grid project-style-2 mb--20`}>
                                    <div className="kc-thumbnail kc-center-div-p-50">
                                        <img src={process.env.PUBLIC_URL + "/images/platform/ob_bcp_ico_01.png"}
                                             alt="bubble"/>
                                    </div>
                                </div>
                                <p style={{textAlign: "center"}}>
                                    별도의 전문지식 없이도
                                    블록체인 네트워크 자동 구축 가능
                                    Cloud 기반의 신속한 구축 및
                                    효율적인 자원 운용
                                    하이퍼레저 패브릭 블록체인 엔진 선택
                                </p>
                            </div>

                            <div className="col-md-4">
                                <div className={`project-grid project-style-2 mb--20`}>
                                    <div className="kc-thumbnail kc-center-div-p-50">
                                        <img src={process.env.PUBLIC_URL + "/images/platform/ob_bcp_ico_02.png"}
                                             alt="bubble"/>
                                    </div>
                                </div>
                                <p style={{textAlign: "center"}}>
                                    빠른 블록체인 서비스 개발을 위한
                                    비즈니스 맞춤형 코나체인 API 제공
                                    (전자 계약, 투표 및 평가)
                                    개발한 스마트 컨트랙트의 자동 배포 및
                                    서비스를 위한 통합 개발 환경 제공
                                </p>
                            </div>

                            <div className="col-md-4">
                                <div className={`project-grid project-style-2 mb--20`}>
                                    <div className="kc-thumbnail kc-center-div-p-50">
                                        <img src={process.env.PUBLIC_URL + "/images/platform/ob_bcp_ico_03.png"}
                                             alt="bubble"/>
                                    </div>
                                </div>
                                <p style={{textAlign: "center"}}>
                                    블록체인을 통해 리워드 내역을
                                    기록하고, 받은 리워드는 코나아이가
                                    보유한 결제 플랫폼(코나카드 플랫폼)을
                                    통해 현금과 같이 편리하게 사용 가능
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="section service-scroll-navigation-area kc-pt--90">
                    <div className="section-heading">
                        <h4 className="kc-title">고객사는 코나체인 BaaS 서비스를 활용하여<br/>
                            빠르고 쉽게 분산형 애플리케이션 서비스를 제공할 수 있습니다.
                        </h4>
                        <p style={{width: "100%"}}>
                            코나체인 플랫폼은 Service, Middleware, Common Core, Blockchain Core, Infra 영역으로 구성됩니다.
                        </p>
                    </div>

                    <div className="row g-0">
                        <div className="kc-center-div-p-90">
                            <img src={process.env.PUBLIC_URL + "/images/platform/ob_bcp_img_03.png"} alt="bubble"/>
                        </div>
                    </div>
                </div>

                <InquirySection/>
                <FooterOne parentClass=""/>
            </main>

        </>
    )
}

export default BlockchainBaaSService;
