import React from 'react';
import {Link} from 'react-router-dom';
import {AnimationOnScroll} from "react-animation-on-scroll";


const BannerHomePageTwo = ({
                               backgroundImageUrl
                               , bannerClassName
                               , topSubTitleText
                               , titleText
                               , button1
                               , button2
                               , bottomSubTitleText
                               , rightImageUrl
                           }
) => {
    return (
        <div className= {`banner banner-style-5 kc-pt--235 ${bannerClassName} `}
             style={{backgroundImage: `url(${process.env.PUBLIC_URL + backgroundImageUrl})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-7">
                        <div className="banner-content">
                            <h5 className="kc-font-color-white" style={{fontWeight: "bold", fontSize: "25px"}}
                                dangerouslySetInnerHTML={{__html: topSubTitleText}}></h5>
                            <br/>
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="kc-title kc-font-color-white"
                                    dangerouslySetInnerHTML={{__html: titleText}}></h1>
                                {button1 &&
                                <Link to={process.env.PUBLIC_URL + "/inquiry"}
                                      className="axil-btn btn-fill-primary kc-axil-btn kc-font-color-white btn-sm"
                                      dangerouslySetInnerHTML={{__html: button1}}></Link>
                                }
                                {button2 &&
                                <Link target="_blank" to={process.env.PUBLIC_URL + "/files/[코나체인] 코나체인 서베이 소개서.pdf"}
                                      className="axil-btn btn-fill-white kc-axil-btn kc-font-color-black btn-sm ml--20"
                                      dangerouslySetInnerHTML={{__html: button2}}></Link>
                                }
                                <br/>
                                <br/>
                                <h5 className="kc-font-color-white" style={{fontWeight: "normal", fontSize: "23px", marginBottom: "0px"}}
                                    dangerouslySetInnerHTML={{__html: bottomSubTitleText}}></h5>

                            </AnimationOnScroll>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BannerHomePageTwo;
