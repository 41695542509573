import React from 'react';
import {Link} from 'react-router-dom';


const HomePageBlogProp = ({colSize, serviceStyle, serviceData}) => {

    return (
		<>
			{serviceData.map((data, index) => (
				<div className={colSize} key={index} >
					<div className={`services-grid ${serviceStyle}`} style={{background: "var(--color-white)"}}>
						<div className="thumbnail">
							<img src={process.env.PUBLIC_URL + data.image} alt="icon" style={{maxHeight: "60px"}} />
						</div>
						<div className="content">
							<h5 className="title" style={{marginBottom: "10px"}}>
								<Link to={process.env.PUBLIC_URL + "/" + `${(data.more_link)}`}><b>{data.title}</b></Link>
							</h5>
							{/*<p>{data.description}</p>*/}
							<Link to={process.env.PUBLIC_URL + "/" + `${(data.more_link)}`} className="kc-link-with-bold">더 알아보기 ></Link>
						</div>
					</div>
			 	</div>
			))}
		</>
    )
}

export default HomePageBlogProp;
