import React from 'react';
import {Link} from 'react-router-dom';
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";


const HomePageBusInfoDataProp = ({colSize, serviceStyle, serviceData}) => {

    return (
		<>
			{serviceData.map((data, index) => (
				<div className={colSize} key={index} >
					<div className={`services-grid ${serviceStyle}`} style={{background: "var(--color-white)"}}>
						<div className="thumbnail">
							<img src={process.env.PUBLIC_URL + data.image} alt="icon" />
						</div>
						<div className="content">
							<h5 className="title" style={{paddingTop:"0px", fontSize: "23px"}}><b>{data.title}</b></h5>
                            <div className="case-study-counterup">
                            <div className="count-number h6">
                                <TrackVisibility once>
                                    {({isVisible}) => (
                                        <span className="number count kc-title-color-blue" style={{verticalAlign : "baseline"}}>
                                        {isVisible ? <CountUp suffix={data.unit} separator="," end={data.count} duration={1} /> : null}
                                    </span>
                                    )}
                                </TrackVisibility>
                                {/*<span className="symbol kc-title-color-blue" style={{verticalAlign : "baseline"}}>&nbsp;{data.unit}</span>*/}
                            </div>
                            </div>
							{/*<p>{data.description}</p>*/}
							{/*<Link to={process.env.PUBLIC_URL + "/" + `${(data.more_link)}`} className="more-btn">Find out more</Link>*/}
						</div>
					</div>
			 	</div>
			))}
		</>
    )
}

export default HomePageBusInfoDataProp;