import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";


const TermsOfUse = () => {

    return (
        <>
            <SEO title="코나체인 | 이용약관"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne />
                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600"
                    titleText="이용약관"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="policy-txt-box">
                                        <p>
                                            [제 1 장 총칙]
                                            <br/>
                                            <br/>제 1 조 (목적)
                                            <br/>이 약관은 코나체인㈜(이하 “회사”라 합니다)가 제공하는 코나체인 서비스를 이용하는 자와 회사 간에 서비스 이용에 필요한 조건, 절차, 당사자의 권리, 의무 등 제반 사항을 규정함을 목적으로 합니다.
                                            <br/>
                                            <br/>제 2 조 (용어의 정의)
                                            <br/>① 이 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
                                            <br/>1. “회원”은 회사가 운영하는 코나체인 서비스를 이용하기 위해 회사가 정한 절차에 따라 이 약관에 동의한 후 회사와 서비스 이용계약을 체결하고 서비스를 제공받는 자를 말합니다. 회원은 이용자에 따라 ‘일반회원’과 ‘법인회원’으로 구분하여 코나체인 서비스에 가입할 수 있습니다.
                                            <br/>2. “코나체인 서비스(이하 “서비스”라 합니다.)”란 블록체인 기술을 기반으로 회사가 개발한 플랫폼을 통해 PC와 이동통신단말기를 이용한 웹과 모바일 환경에서 전자투표, 설문 조사 등을 할 수 있도록 회사가 제공하는 제반 서비스를 말합니다.
                                            <br/>3. “전자투표”는 회사가 제공하는 서비스를 통해 회원이 전자적 방법에 의하여 의결권을 행사하는 것을 말합니다.
                                            <br/>4. “이용기관”이란 회원으로서 회사가 제공하는 서비스를 활용하여 주주총회, 설문 또는 여론 등의 각종 조사 등의 업무를 등록하고 회사에 해당 업무를 위탁하는 자를 말합니다.
                                            <br/>5. “코나체인 웹사이트(이하 “사이트”라 합니다)”란 서비스를 제공하기 위해 회사가 운영하는 웹사이트를 말합니다.
                                            <br/>6. “ID”란 회원 식별과 회원의 서비스 이용을 위하여 회원이 지정하는 이메일 주소를 말합니다.
                                            <br/>7. “비밀번호”란 회원이 자신의 아이디와 일치하는 회원임을 확인하기 위해 선정한 문자, 특수문자, 숫자 등의 조합을 말합니다.
                                            <br/>8. “게시물”이란 회원이 서비스를 이용함에 있어 서비스상에 게시한 투표, 조사, 평가 등의 안건을 포함하여 부호·문자·음성·음향·화상·동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
                                            <br/>
                                            <br/>② 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관련 법령, 서비스 안내 및 회사의 기타 약관에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.
                                            <br/>제 3 조 (약관의 효력 및 변경)
                                            <br/>① 이 약관은 서비스 화면 또는 사이트에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이 발생됩니다.
                                            <br/>② 회사는 「상법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령의 개정이나 정당한 사유가 발생한 경우 이 약관을 개정할 수 있습니다.
                                            <br/>③ 회사가 이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 서비스 내에 적용일자 7일 이전부터 적용일자 전일까지 서비스 화면 또는 사이트를 통하여 공지하며, 회원에게 불리하게 약관내용을 변경하는 경우 또는 회원의 권리·의무에 중대한 영향을 미치는 사항을 변경하는 경우에는 적용일자 30일 전부터 공지하거나 제4조(회원에 대한 통지)에서 정한 방법으로 개별 통지할 수 있습니다. 다만, 법령 개정 등으로 인하여 긴급하게 약관을 변경한 경우에는 변경된 약관을 서비스 화면 또는 사이트에 게시하고 회원에게 제4조(회원에 대한 통지)에서 정한 방법으로 사후 통지합니다.
                                            <br/>④ 회원은 변경된 약관에 대해 변경된 약관의 적용일자 전일까지 거부의사를 표명하고 서비스 계약을 해지할 수 있습니다. 회원이 거부하는 경우 회사는 당해 회원과의 서비스 계약을 해지하고 서비스 제공을 중지할 수 있습니다. 만약, 회원이 변경된 약관의 적용일자 전일까지 거부의사를 표명하지 않거나, 시행일 이후에 서비스를 이용하는 경우에는 변경된 약관에 대해 동의한 것으로 간주합니다.
                                            <br/>⑤ 이 약관은 회사와 회원 간에 성립되는 서비스 이용계약의 기본 약정입니다. 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 "개별약관"이라 합니다)을 정하여 미리 공지할 수 있습니다. 회원이 이러한 개별약관에 동의하고 특정 서비스를 이용하는 경우에는 개별약관이 우선적으로 적용되고, 이 약관은 보충적인 효력을 갖습니다. 개별약관의 변경에 관해서는 개별약관에 특별한 규정이 없는 한 본 조항을 준용합니다.
                                            <br/>
                                            <br/>제 4 조 (회원에 대한 통지)
                                            <br/>회사는 회원에게 서비스 화면 또는 사이트에 게시하는 방법으로 공지할 수 있으며, 회원에 대한 개별 통지를 하는 경우 이 약관에 별도 규정이 없는 한 회원이 제공한 전자우편(E-mail), SMS 등으로 통지할 수 있습니다.
                                            <br/>
                                            <br/>제 5 조 (약관 외 준칙)
                                            <br/>이 약관에 명시되지 않은 사항은 「상법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령 등의 규정에 의하며, 이외 정함이 없는 경우에는 일반적인 상·관례를 따릅니다. )
                                            <br/>
                                            <br/>[제 2 장 서비스 이용계약]
                                            <br/>
                                            <br/>제 6 조 (서비스의 범위)
                                            <br/>회사가 회원에게 제공하는 서비스의 종류는 다음 각 호와 같습니다.
                                            <br/>1. 전자투표 및 주주총회의 투표 안건 등록, 결과 등의 관리
                                            <br/>2. 투표, 설문, 여론 등 각종 안건 등록 및 관리
                                            <br/>3. 평가 안건 등록 및 관리
                                            <br/>4. 기타 위 각 호와 관련하여 회원의 편의를 증대시키기 위해 제공하는 서비스
                                            <br/>
                                            <br/>제 7 조 (이용계약의 성립)
                                            <br/>① 일반회원: 이용자가 본인확인 절차 이행 후 이 약관에 동의하는 것으로 이용계약은 성립됩니다.
                                            <br/>② 법인회원: 주주총회 등의 전자투표 관리업무를 회사에 위탁하려는 이용기관의 법인임을 인증하는 절차에 따른 법인회원 신청에 대하여 회사가 이를 승인함으로써 이용계약은 성립됩니다.
                                            <br/>
                                            <br/>제 8 조 (서비스 이용신청)
                                            <br/>이용자는 코나체인 서비스 화면 또는 사이트 등 온라인으로 회사 소정의 가입신청 양식에서 요구하는 사항을 기록하여 서비스 이용을 신청합니다.
                                            <br/>
                                            <br/>제 9 조 (이용신청의 승낙)
                                            <br/>① 회사는 회원이 제7조에서 정한 모든 사항을 정확히 기재하여 이용신청을 하였을 때 이용신청을 승낙할 수 있습니다.
                                            <br/>② 회사는 다음 각 호의 해당하는 이용신청에 대하여는 승낙을 유보하거나 취소할 수 있습니다.
                                            <br/>1. 이름이 실명이 아닌 경우
                                            <br/>2. 다른 사람의 명의를 사용하여 신청한 경우
                                            <br/>3. 이용신청 시 필요내용을 허위로 기재하여 신청한 경우
                                            <br/>4. 회원이 제8조 제1항 또는 제2항을 위반한 경우
                                            <br/>5. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우
                                            <br/>6. 회사가 정한 이용신청요건이 미비 되었을 때
                                            <br/>7. 기타 회사의 사유로 이용이 어려운 경우
                                            <br/>
                                            <br/>[제 3 장 계약당사자의 의무]
                                            <br/>제 10 조 (회사의 의무)
                                            <br/>① 회사는 서비스 제공과 관련해서 알고 있는 회원의 신상정보를 본인의 승낙 없이 제 3자에게 누설, 배포하지 않습니다. 단, 「전기통신사업법」 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우, 각급 회사에서 학생지도 상의 목적에 의하여 회사장의 요청이 있을 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우에는 그러하지 않습니다.
                                            <br/>② 본 조 제1항의 범위 내에서, 회사는 업무와 관련하여 회원 전체 또는 일부의 개인정보에 관한 통계 자료를 작성하여 이를 사용할 수 있고, 서비스를 통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 설정을 변경할 수 있습니다.
                                            <br/>제 11 조 (회원의 의무)
                                            <br/>① 회원은 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.
                                            <br/>1. 다른 회원의 ID를 부정하게 사용하는 행위
                                            <br/>2. 서비스에서 얻은 정보를 회사의 사전승낙 없이 회원의 이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위
                                            <br/>3. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위
                                            <br/>4. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위
                                            <br/>5. 범죄와 결부된다고 객관적으로 판단되는 행위
                                            <br/>6. 기타 관계법령에 위배되는 행위
                                            <br/>② 회원은 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 합니다.
                                            <br/>③ 회원은 내용별로 회사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.
                                            <br/>④ 회원은 회사의 사전 승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며, 영업활동의 결과와 회원이 약관에 위반한 영업활동을 이용하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다. 회원은 이와 같은 영업활동에 대하여 회사에 대하여 손해배상의무를 집니다.
                                            <br/>⑤ 회원은 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
                                            <br/>
                                            <br/>[제 4 장 서비스 이용]
                                            <br/>제 12 조 (회원 아이디(ID)와 비밀번호 관리에 대한 회원의 의무)
                                            <br/>① 아이디(ID)와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디(ID)와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
                                            <br/>②자신의 아이디(ID)가 부정하게 사용된 경우 회원은 반드시 회사에 그 사실을 통보해야 합니다.
                                            <br/>
                                            <br/>제 13조 (정보의 제공)
                                            <br/>회사는 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 회원이 별도의 정보 제공 동의한 경우에 한하여 제4조(회원에 대한 통지)에서 정한 방법으로 회원에게 제공할 수 있습니다.
                                            <br/>
                                            <br/>제 14 조 (회원의 게시물)
                                            <br/>회사는 회원이 게시하거나 등록하는 서비스 및 사이트 내의 내용물이 다음 각 호에 해당한다고 판단되는 경우에는 사전통지 없이 해당 게시물을 삭제할 수 있습니다.
                                            <br/>1. 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
                                            <br/>2. 공공질서 및 미풍양속에 위반되는 내용인 경우
                                            <br/>3. 범죄적 행위에 결부된다고 인정되는 내용일 경우
                                            <br/>4. 회사의 저작권, 제 3 자의 저작권 등 기타 권리를 침해하는 내용인 경우
                                            <br/>5. 회사에서 규정한 게시기간을 초과한 경우
                                            <br/>6. 기타 관계법령에 위반된다고 판단되는 경우
                                            <br/>
                                            <br/>제 15 조 (게시물의 저작권)
                                            <br/>서비스에 게재된 자료에 대한 권리는 다음 각 호와 같습니다.
                                            <br/>1. 게시물에 대한 권리와 책임은 게시자 에게 있으며 회사는 게시자의 동의 없이는 이를 서비스 내 게재 이외에 영리적 목적으로 사용할 수 없습니다. 단, 비영리적인 경우에는 그러하지 아니하며 또한 회사는 서비스 또는 사이트 내의 게재 권을 갖습니다.
                                            <br/>2. 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
                                            <br/>
                                            <br/>제 16 조 (서비스 이용시간)
                                            <br/>① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 정한 날이나 시간은 그러하지 않습니다.
                                            <br/>② 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.
                                            <br/>③ 주주총회 서비스의 이용은 기본적으로 주주총회일 10일 전부터 주주총회일 전날 오후5시까지로 합니다. 다만, 법인회원의 요청에 따라 회사가 필요하다고 인정하는 경우에는 조정할 수 있습니다.
                                            <br/>
                                            <br/>제 17조 (서비스 이용 책임)
                                            <br/>회원은 서비스를 이용하여 불법상품을 판매하는 영업활동을 할 수 없으며 특히 해킹, 광고, 음란사이트 등을 통한 상업행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않습니다.
                                            <br/>
                                            <br/>제 18 조 (서비스 제공의 중지)
                                            <br/>① 회사는 다음 각 호에 해당하는 경우 서비스 제공의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
                                            <br/>1. 정전, 설비 장애 및 이용량의 폭주 등으로 인해 서비스의 정상적인 이용에 장애가 있는 경우
                                            <br/>2. 서비스의 정상적인 제공을 위한 설비 점검, 보수 및 개발 등으로 인해 부득이한 경우
                                            <br/>3. 회사와 제휴나 협력업체와의 계약 종료 등과 같이 회사의 서비스 운영, 업무상의 사정으로 인한 경우
                                            <br/>4. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
                                            <br/>5. 천재지변, 국가 비상 사태 등 기타 불가항력적 사유가 있는 경우
                                            <br/>
                                            <br/>[제 5 장] 주주총회 서비스의 이용
                                            <br/>
                                            <br/>제19조 전자투표권자의 확정
                                            <br/>① 주주총회 전자투표권자는 법인회원이 제출한 주주명부에 기재되어 있는 주주로 하며, 다음 각호의 어느 하나에 해당하는 경우네는 해당 주주에 갈음하여 다음 각호에서 정하는 자를 전자투표권자로 합니다.
                                            <br/>1. 주식이 자본시장과 금융투자 업에 관한 법률에 따른 투자회사 재산 또는 투자신탁재산에 속하는 경우: 그 투자회사 또는 투자신탁의 집합투자업자
                                            <br/>2. 외국인(금융투자업규정에 따른 외국인을 말함)주주가 상임대리인을 선임한 경우: 그 상임대리인
                                            <br/>3. 주식이 해외 주식예탁증원의 원주인 경우: 그 원주의 보관기관
                                            <br/>② 법인회원은 전자등록 된 주주명부 등을 기초로 하여 다음 각 호의 사항이 포함된 전자투표권 자 명부를 전자투표 기간 초일의 전날까지 확정하여야 합니다.
                                            <br/>1. 주주 및 전자투표권자의 성명(법인인 경우에는 명칭), 주소, 주민등록번호(법인인 경우에는 사업자등록번호, 외국인인 경우에는 투자등록번호 등)
                                            <br/>2. 주주가 소유한 주식의 종류와 수
                                            <br/>3. 전자투표권자가 의결권을 행사할 수 있는 주식 수
                                            <br/>4. 그 밖에 전자투표관리업무에 필요한 사항
                                            <br/>③ 회사는 다음 각 호의 어느 하나에 해당하는 사유가 발생한 경우에는 전자투표권자 명부를 정정할 수 있습니다.
                                            <br/>1. 주주명부 기재사항에 누락 또는 오기 등이 발견되어 해당 위탁자가 전자투표권자 명부의 정정을 요청하는 경우
                                            <br/>2. 주주명부에 상임대리인이 기재되지 아니하여 해당 상임대리인이 전자투표권 자 명부의 정정을 요청하는 경우
                                            <br/>
                                            <br/>제 20 조 (주주총회 등록 시 참고자료 등의 전자등록)
                                            <br/>① 주주총회 관련 전자투표를 등록한 회원은 다음 각 호의 자료를 주주총회 일의 2주 전까지 전자 등록하여야 합니다.
                                            <br/>1. 주주총회 의안
                                            <br/>2. 주주총회 소집의 통지서 및 공고문
                                            <br/>3. 상법 및 그 밖의 관계법규에 따라 의결권 행사에 제한을 받는 주주가 있는 경우 그 내역
                                            <br/>4. 의결권 행사에 관한 참고자료 등 법인회원이 필요하다고 인정하는 자료
                                            <br/>5. 그 밖에 회사가 전자투표관리업무에 필요하다고 인정하는 자료
                                            <br/>② 법인회원은 제1항의 2호의 주주총회 소집의 통지서 및 공고문에 다음 각 호의 사항을 기재하여야 합니다.
                                            <br/>1. 주주가 전자투표의 방법으로 의결권을 행사할 수 있다는 뜻
                                            <br/>2. 전자투표 시행 주소
                                            <br/>3. 전자투표 기간
                                            <br/>4. 전자투표의 의결권 산입 등 주주총회에서의 전자투표 처리방법
                                            <br/>5. 그 밖에 주주의 전자투표에 필요한 사항
                                            <br/>6. 회사는 제1항에 따라 법인회원으로부터 제출 받은 의결권 행사에 관한 참고자료 등 위탁자가 필요하다고 인정하는 자료를 전자투표 기간 동안 조회할 수 있도록 하여야 합니다.
                                            <br/>제21조 (주주총회의 이용신청 및 전자투표 방법)
                                            <br/>① 주주총회 투표 신청 시 회원의 본인확인 절차 이후 주주총회일 3주전까지 주주명부(주주총회 기준일 현재의 주주 상황을 정확히 반영하고 있는 명부를 의미합니다.)를 전자등록 등의 방법으로 제출하여야 합니다.
                                            <br/>② 회원은 휴대폰 본인인증을 통하여 본인 확인절차를 완료한 후 의안 별로 찬성, 반대 또는 기권 의사 표시를 함으로써 주주총회 투표 시 의결권을 행사할 수 있습니다.
                                            <br/>③ 주주총회 참여자는 의결권을 행사할 수 있는 주식 전부에 대하여 전자투표를 하여야 합니다. 다만, 일부 주식에 대한 전자투표가 부득이한 경우로서 다음 각 호의 경우에는 그러하지 아니합니다.
                                            <br/>1. 집합투자업자가 집합투자재산에 속하는 주식에 대하여 전자투표를 하는 경우
                                            <br/>2. 상임대리인이 외국인 주주의 일부 주식에 대한 의결권 행사지시에 따라 전자투표를 하는 경우
                                            <br/>3. 우리 사주 조합의 대표자가 조합 계정이나 조합원 계정 보유 주식에 대하여 전자투표를 하는 경우
                                            <br/>
                                            <br/>[제 6 장 계약해지 및 이용제한]
                                            <br/>
                                            <br/>제 22 조 (계약해지 및 이용제한)
                                            <br/>① 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 서비스 화면 또는 사이트를 통해 회사에 해지 신청을 하여야 합니다.
                                            <br/>② 회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.
                                            <br/>1. 타인의 서비스 ID 및 비밀번호를 도용한 경우
                                            <br/>2. 서비스 운영을 고의로 방해한 경우
                                            <br/>3. 가입한 이름이 실명이 아닌 경우
                                            <br/>4. 같은 사용자가 다른 ID로 이중등록을 한 경우
                                            <br/>5. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
                                            <br/>6. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획 또는 실행하는 경우
                                            <br/>7. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
                                            <br/>8. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우
                                            <br/>9. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
                                            <br/>10. 회사, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우
                                            <br/>11. 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우
                                            <br/>12. 타인의 개인정보, 회원의 ID 및 비밀번호를 부정하게 사용하는 경우
                                            <br/>13. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우
                                            <br/>14. 본 약관을 포함하여 기타 회사가 정한 이용조건에 위반한 경우
                                            <br/>
                                            <br/>[제 7 장 손해배상 등]
                                            <br/>
                                            <br/>제 24조 (양도 금지)
                                            <br/>회원은 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없습니다.
                                            <br/>
                                            <br/>제 25 조 (손해배상)
                                            <br/>회사는 서비스 요금이 무료인 서비스 이용과 관련하여 회원에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.
                                            <br/>
                                            <br/>제 26 조 (면책조항)
                                            <br/>① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
                                            <br/>② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
                                            <br/>③ 회사는 회원이 서비스를 이용하여 본인이 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그밖에 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
                                            <br/>④ 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.
                                            <br/>
                                            <br/>제 27 조 (민원의 해결 및 관할법원)
                                            <br/>① 회원은 서비스 이용과 관련하여 정당한 의견 또는 요구사항이 있는 경우 아래의 연락처를 통해 회사에 의견을 제시할 수 있습니다.
                                            <br/>1. 상호: 코나체인 주식회사
                                            <br/>2. 주소: 서울특별시 영등포구 은행로 25 5층(여의도동, 안원빌딩), 코나체인 서비스 담당
                                            <br/>3. 전화번호: 1668-3610
                                            <br/>4. 전자우편(E-mail): consulting@konachain.com
                                            <br/>
                                            <br/>[부칙]
                                            <br/>-이 약관은 2022년 12월 20일부터 시행합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterOne parentClass=""/>
            </main>
        </>
)
}

export default TermsOfUse;
