import React from 'react';
import {FaFileAlt, FaFileDownload, FaFileWord} from "react-icons/fa";

const AttachmentListGenerate = ({
                                    attachmentObject
    , onDeleteHandler
    , isEdit = false
}) => {

    const removeFile = (fileName) => {
        console.log("Removing")
        console.log(fileName);
        if(onDeleteHandler){
            onDeleteHandler(fileName);
        }
    }

    const getFileNameFromUrl = (url) => {
        let filename = '';
        try {
            filename = new URL(url).pathname.split('/').pop();
        } catch (e) {
            console.error(e);
        }
        return filename;
    }

    const getExtensionIcon = (url) => {
        let fileName = getFileNameFromUrl(url);
        let extension = (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName): undefined;
        console.log(extension);
        if(extension && extension[0] && (extension[0] === 'doc' || extension[0] === 'docx' )){
            return <FaFileWord/>;
        } else if(extension && extension[0] && extension[0] === 'hwp'){
            return <FaFileAlt/>;
        } else {
            return <FaFileDownload/>
        }

    }

    return (
        <div className="container">
            {
                attachmentObject.map((fileName) =>
                    <li style={{listStyleType: "none"}}>
                        {isEdit &&
                            <button style={{margin: "2px 10px 2px 0px"}} type="button"
                                    onClick={(e)=>removeFile(e.target.value)}
                                    value={fileName}
                                    className="btn btn-danger btn-sm">Remove</button>
                        }
                        <a href={fileName} target="_blank"> {getExtensionIcon(fileName)}  {getFileNameFromUrl(fileName)}</a>
                    </li>
                )
            }
        </div>
    )
}




{/*<li><Link><FaFilePdf/> survey-file.pdf</Link></li>*/}
{/*<li><Link><FaFileAlt/> survey-file.hwp</Link></li>*/}

export default AttachmentListGenerate;
