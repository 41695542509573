import dayjs from "dayjs";
import {DEFAULT_CONSTANTS} from "./Constants";
import {CommonUtil} from "../helpers";

const pluginDayjsUtc = require('dayjs/plugin/utc');
const pluginDayjsTimezone = require('dayjs/plugin/timezone');
dayjs.extend(pluginDayjsUtc);
dayjs.extend(pluginDayjsTimezone);

export const DateUtil = {
    parse,
    now,
    format,
    tz,
    getTimezoneStr,
    isDateObj,
    isValidDate,
    add,
    addDay,
    addMonth,
    addYear,
    subtract,
    subDay,
    subMonth,
    subYear,
    firstOfMonth,
    endOf,
    diff,
    isAfter,
    isBefore,
    toDate,
    print,
    toApiDate,
    toApiDateTime,
    toString
};
const DATE_FORMAT = "YYYY-MM-DD hh:mm A";
function parse(date, formatStr, local) {
    if(!formatStr) formatStr = DATE_FORMAT;
    return dayjs(date, formatStr, local);
}

function now() {
    return parse(undefined);
}

function format(dateObj, formatStr) {
    if(!formatStr) formatStr = DATE_FORMAT;
    return dateObj && isDateObj(dateObj)
        ? dateObj.format(formatStr)
        : format(parse(dateObj), formatStr);
}

function tz(dateObj, timezone) {
    return parse(dateObj).tz(timezone);
}

function getTimezoneStr() {
    return dayjs.tz.guess();
}

function isDateObj(dateObj) {
    return dayjs.isDayjs(dateObj);
}

function isValidDate(date) {
    const invalidDateStr = "Invalid Date";
    const dateStr = isDateObj(date) ? format(date) : date;
    return !!dateStr && dateStr !== invalidDateStr;
}

function add(addUnit, unitType, dateObj) {
    return parse(dateObj).add(addUnit, unitType);
}

function subtract(subUnit, unitType, dateObj) {
    return parse(dateObj).subtract(subUnit, unitType);
}

function addDay(addUnit, dateObj) {
    return add(addUnit, "d", dateObj);
}

function addMonth(addUnit, dateObj) {
    return add(addUnit, "M", dateObj);
}

function addYear(addUnit, dateObj) {
    return add(addUnit, "y", dateObj);
}

function subDay(addUnit, dateObj) {
    return subtract(addUnit, "d", dateObj);
}

function subMonth(addUnit, dateObj) {
    return subtract(addUnit, "M", dateObj);
}

function subYear(addUnit, dateObj) {
    return subtract(addUnit, "y", dateObj);
}

function firstOfMonth(dateObj) {
    return parse(dateObj).date(1)
}

function endOf(endUnit, dateObj) {
    return parse(dateObj).endOf(endUnit);
}

function diff(leftDate, rightDate, unitType, decimalDiff) {
    return parse(leftDate).diff(rightDate, unitType, decimalDiff);
}

function isAfter(leftDate, rightDate, unitType) {
    return parse(leftDate).isAfter(parse(rightDate), unitType);
}

function isBefore(leftDate, rightDate, unitType) {
    return parse(leftDate).isBefore(parse(rightDate), unitType);
}

function toDate(dateObj) {
    return parse(dateObj).toDate();
}

function print(dateObj, formatStr) {
    const parseDate = parse(dateObj);
    return isValidDate(dateObj) ?
        format(dateObj, formatStr) :
        CommonUtil.getEmptyDataPlaceholder();
}

function toApiDate(dateObj, formatStr = DEFAULT_CONSTANTS.API_REQUEST_DATE_FORMAT) {
    return dateObj ? format(dateObj, formatStr) : null;
}

function toApiDateTime(dateObj, formatStr = DEFAULT_CONSTANTS.API_REQUEST_DATE_TIME_FORMAT) {
    return dateObj ? format(dateObj, formatStr) : null;
}

function toString(dateObj) {
    return format(dateObj, DEFAULT_CONSTANTS.DATE_TIME_TO_STRING_FORMAT);
}
