import React from 'react';
import {FaDotCircle} from "react-icons/fa";


const PlatformPanelDataTable = () => {
    return (
        <>

            <table className="table table-bordered" >
                <thead>
                <tr className="table-dark align-middle">
                    <th scope="col" style={{width: "25%"}} colSpan={2} >회원(패널)의 구분</th>
                    <th scope="col" style={{width: "35%"}}>회원(패널)의 특성</th>
                    <th scope="col" style={{width: "10%"}}>회원(패널) 수</th>
                    <th scope="col" style={{width: "10%"}}>신뢰성</th>
                    <th scope="col" style={{width: "10%"}}>대표성</th>
                    <th scope="col" style={{width: "10%"}}>Big Data <br/>분석 연계성</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="align-middle table-primary" style={{borderBottomWidth: "1px"}} rowSpan={3}>지역화폐앱<br/> 회원 pool</td>
                    <td className="table-primary" style={{borderBottomWidth: "1px"}}>Konachain Pool</td>
                    <td align={"left"}>
                        코나아이에서 진행하는 연중 Online 조사 서비스에 참여가 가능하도록 약관 동의가 완료된 패널
                        <ul style={{color: "var(--color-primary)"}}>
                            <li>Junior 패널 약 20만 (만15~19세)</li>
                            <li>Senior 패널 약 100만 (만 60세 이상)</li>
                        </ul>
                    </td>

                    <td className="align-middle" align={"center"}>약 803만명</td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                </tr>
                <tr>
                    <td className="table-primary" style={{borderBottomWidth: "1px"}} >컨택트 pool
                    </td>
                    <td align={"left"}>
                        코나아이에서 진행하는 Online 조사에 참여하여 주기적으로 설문조사에 응답하고 있는 패널

                    </td>

                    <td className="align-middle" align={"center"}>약 13만명</td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                </tr>
                <tr>
                    <td className="table-primary" style={{borderBottomWidth: "1px"}}>가맹점주 Pool
                    </td>
                    <td align={"left"}>
                        점포 조사를 위하여 지역별로 구축한 가맹점주 패널 (지역화폐 가맹점 포탈에 등록된 회원으로 구성
                    </td>

                    <td className="align-middle" align={"center"}>약 2만명
                    </td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                </tr>

                <tr>
                    <td className="table-primary" style={{borderBottomWidth: "1px"}} colSpan={2}>코나카드 회원 pool</td>
                    <td align={"left"}>
                        코나카드 결제 정보와 연계분석이 가능한 전국 패널
                    </td>

                    <td className="align-middle" align={"center"}>약 2만명
                    </td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                </tr>
                <tr className="table-secondary">
                    <td className="table-primary" colSpan={2}>Total Access Panel Pool</td>
                    <td align={"left"}>
                        코나아이가 보유한 Access Panel Pool 합계
                    </td>

                    <td className="align-middle" align={"center"}>약 822만명
                    </td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                </tr>
                <tr className="align-middle table-info" style={{height: "70px"}}>
                    <td style={{backgroundColor: "var(--color-primary)", color: "white", fontWight: "bold"}} colSpan={2}>KONACHAIN Survey Panel</td>
                    <td align={"left"}>
                        코나체인 서베이의 회원으로 가입한 Panel 합계

                    </td>

                    <td className="align-middle" align={"center"}>약 90만명
                    </td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                    <td className="align-middle" align={"center"}><span style={{color: "var(--color-primary)"}}><FaDotCircle/> </span></td>
                </tr>

                </tbody>
            </table>

        </>
    )
}

export default PlatformPanelDataTable;
