import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import DocumentViewCss from "../../assets/css/document-view-main.css"
import CustomeCSS from "../../assets/css/kc-custome.css"
import SplashFooter from "../../common/footer/SplashFooter";
import PlatformPanelDataTable from "../../component/product/PlatformPanelDataTable";
import LineDraw from "./LineDraw";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";


const VotingServiceDocuments = () => {

    return (
        <>
            <SEO title="코나체인 | 코나체인 가이드"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>

                <div className="pt--150 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
                    <div className="container">
                        <div className="main-content-wrapper">
                            <div className="container-fluid">
                                <div className="main-content p-t-40">
                                    <aside className="sidebar-navigation">
                                        <nav className="doc-nav-main">
                                            <h5>코나체인 가이드</h5>

                                            <ol className="doc-nav">
                                                <li>
                                                    <a className="doc-nav-link" href="#section-1">코나체인</a>
                                                    <ol className="sub-nav">
                                                        <li><a className="doc-nav-link" href="#section-1-1">코나체인이란?</a></li>
                                                        <li><a className="doc-nav-link" href="#section-1-2">Panel 설명</a></li>
                                                        <li><a className="doc-nav-link" href="#section-1-3">문의하기</a></li>
                                                    </ol>
                                                </li>
                                                <li><a className="doc-nav-link" href="#section-2">설문 제작</a>
                                                    <ol className="sub-nav">
                                                        <li><a className="doc-nav-link" href="#section-2-1">설문제작 시작하기</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-2">제목, 질문, 답변유형 설정</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-3">선택형 설정</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-4">순위형 설정</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-5">격자 질문/선택형 설정</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-6">척도형 설정</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-7">서술형 설정</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-8">선택 항목 설정</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-9">응답 후 옵션 설정</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-10">설문조사 등록 설정</a></li>
                                                        <li><a className="doc-nav-link" href="#section-2-11">설문조사 배포</a></li>
                                                    </ol>
                                                </li>
                                                <li><a className="doc-nav-link" href="#section-3">설문 관리</a>
                                                    <ol className="sub-nav">
                                                        <li><a className="doc-nav-link" href="#section-3-1">설문조사 결과 확인</a></li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </nav>
                                    </aside>
                                    <main className="data_main">
                                        <div className="filterable-wrapper">
                                            <SectionOne/>
                                            <SectionTwo/>
                                            <SectionThree/>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SplashFooter/>
            </main>
        </>
    )
}

export default VotingServiceDocuments;
