import React, {useEffect, useState} from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import BlogGridTwo from '../../component/blog/BlogGridTwo';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import {blogApi} from "../../api/blogApi";
import PaginationUtil from "../../helpers/PaginationUtil";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";



const ResearchTips = () => {

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        let _pageNumber =  event.selected;
        setSearchParams(prevState => {
            return {
                ...prevState,
                pageNumber: _pageNumber
            }
        })
        setSearchOnChange(true);
        console.log(
            `User requested page number ${event.selected}`
        );
    };

    const [searchOnChange, setSearchOnChange] = useState(false);
    const [searchParams, setSearchParams] = useState({
        pageSize : 6
        , pageNumber : 0
        , categoryId:4
    });

    const [listInfo, setListInfo] = useState({
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
    });

    useEffect(() => {
        handleSearch();
    }, [])

    useEffect(() => {
        if(searchOnChange) {
            handleSearch();
            setSearchOnChange(false);
        }
    }, [searchOnChange])

    const handleSearch = () => {
        console.log("calling handleSearch ... ", {searchParams});
        blogApi.getBlogList(searchParams)
            .then(res => {
                    setListInfo(prevState => {
                        return {
                            ...prevState,
                            ...res.data
                        }
                    })
                }
            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };


    return (
        <>
        <SEO title="코나체인 | 리서치 팁" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600"
                    titleText="리서치 팁"
                />

                <div className="section-padding-equal">
                    <div className="container">
                        <div className="row">
                            <BlogGridTwo data={listInfo.content} colSize="col-lg-4"/>
                            <PaginationUtil
                                handlePageClick={handlePageClick}
                                totalPages={listInfo.totalPages}
                            />
                        </div>
                    </div>
                </div>
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ResearchTips;
