import React from 'react';
import {Link} from 'react-router-dom';
import ServiceStudyData from '../../data/homepage/ServiceData.json';


const HomePageServiceInfoTop = () => {

    return (

        <div className="kc-pt--90 pt_sm--80 case-study-page-area bg-color-light">
            <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className={`case-study-featured-thumb thumb-1`}>
                                <img src={process.env.PUBLIC_URL + "/images/home/image-1.2.png"} alt="IT 기반의 설문조사!
핵심은 빅데이터와의 연계!" width={630} height={521} />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1 kc-vertical-center" >
                            <div className="case-study-featured">
                                <div className="section-heading heading-left">
                                    <h2 className="kc-title">
                                        IT 기반의 설문조사!
                                        <br/>핵심은 빅데이터와의 연계!
                                    </h2>
                                    <p>
                                        코나체인은 설문조사와
                                        <br/>연계된 공공데이터와  소비데이터를 함께 분석합니다.
                                    </p>
                                    <Link to={process.env.PUBLIC_URL + "/trend-report"} className="axil-btn kc-axil-btn-2 btn-fill-primary btn-large">더 알아보기</Link>
                                </div>

                            </div>
                        </div>
                    </div>
            </div>
        </div>

    )
}

export default HomePageServiceInfoTop;
