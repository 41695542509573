import React from 'react';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import FormTwo from '../../component/contact/FormTwo';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ContactHeader from "../../component/contact/ContactHeader";
import Accordion from "react-bootstrap/Accordion";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";


const CustomerContact = () => {
    return (
        <>
            <SEO title="코나체인 | 서비스 문의"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/inquiry-bg.jpg"
                    bannerClassName="kc-min-height--600"
                    titleText="서비스 문의"
                />
                <div className="container mt--80">

                    <SectionTitle
                        subtitle=""
                        title="설문조사 문의"
                        description="코나체인 설문조사는 지역기반 조사, 마케팅조사, 소비자조사, 기업고객조사, 유통점조사를 수행합니다."
                        textAlignment=""
                        textColor=""
                    />

                        <Accordion defaultActiveKey="2">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>문의 가이드</Accordion.Header>
                                <Accordion.Body>
                                    <ContactHeader/>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className="section mt--80">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="contact-form-box shadow-box mb--30">
                                            <GoogleReCaptchaProvider
                                                reCaptchaKey="6Lf5NgkjAAAAAE5w39bO4_bqI5AuBRfX0U5vQolu"
                                                scriptProps={{ async: true }}
                                                language="ko"
                                            >
                                                <FormTwo/>
                                            </GoogleReCaptchaProvider>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                                        <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--50"
                                             style={{marginBottom: "120px"}}>
                                            <h4 className="title">TEL</h4>
                                            <p>평일 8:30 ~ 17:30<br/>점심시간 11:00 ~ 12:00
                                            </p>
                                            <h4 style={{fontSize: "20px"}} className="phone-number kc-font-color-white"><a
                                                href="tel:1668-3610">1668-3610</a></h4>
                                        </div>
                                        <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--50"
                                             style={{marginBottom: "120px"}}>
                                            <h4 className="title">Email</h4>
                                            <p>업무 시간 동안 24시간 이내에 <br/>다시 연락을 드릴 것입니다.
                                            </p>
                                            <h4 style={{fontSize: "20px"}} className="phone-number kc-font-color-white"><a
                                                href="mailto:consulting@konachain.com">consulting@konachain.com</a></h4>
                                        </div>
                                        <div className="contact-info mt--50">
                                            <h4 className="title">주소</h4>
                                            <h4 style={{fontSize: "20px"}} className="phone-number kc-font-color-white">코나체인 주식회사</h4>
                                            <p style={{margin: "0px", width: "100%"}}>
                                                <br/>
                                                KONA CHAIN CO., LTD.(약호 KONA CHAIN)
                                                서울시 영등포구 은행로 25, 5층(여의도동, 안원빌딩)
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mt--80 mb--100">

                                        <h4>개인정보 수집 및 이용 동의<span style={{color:"red", fontSize: "16px"}}>(필수)</span></h4>
                                        <blockquote className="wp-block-quote" style={{margin:"10px 0px", borderRight: "0px"}}>
                                            <h6>개인정보 수집 및 이용 동의</h6>
                                            <ol className="list-style">
                                                <li>개인정보의 수집 및 이용 목적 : 조사문의에 대한 답변</li>
                                                <li>수집하려는 개인정보의 항목 : 회사명, 부서/직급, 성명, 회사연락처, 회사 E-mail</li>
                                                <li>개인정보의 보유 및 이용기간 : 1년</li>
                                                <li>동의를 거부할 수 있으며, 동의 거부 시 조사에 대한 서비스가 제공되지 않습니다.</li>
                                            </ol>
                                        </blockquote>

                                    </div>

                                </div>
                            </div>

                        </div>
                </div>


                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default CustomerContact;
