import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import TimeToThrowServiceHeader from "../../component/service/TimeToThrowServiceHeader";
import InquirySection from "../../component/homepage/InquirySection";
import BannerHomePageThree from "../../component/banner/BannerHomePageThree";
import QuotesArea from "../../component/service/QuotesArea";
import {Link} from "react-router-dom";

const TimeToThrowAway = () => {
    const quoteTextList = [
        "일회성이 아닌 꾸준한 활동을 장려하고 싶어요."
        , "모든 환경 활동에 대해서 인센티브를 지급할 수 있나요?"
        , "지급된 인센티브를 다양한 형식으로 활용하고 싶어요"
    ]

    return (
        <>
            <SEO title="코나체인 | 환경 종합 관리 서비스"/>
        <ColorSwitcher />

            <main className="main-wrapper">
                <HeaderOne/>

                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/service-bg-5.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--210"
                    titleText = "환경 종합 관리 서비스"
                    subTitleText = "우리동네 쓰레기, 환경미화! <br/>인센티브 받으면서 관리하세요!"
                />

                <QuotesArea
                    bgClassName = "bg-color-light"
                    quotesTitle = "고객의 경험과 생각을<br/>데이터로 전달합니다."
                    quotesImageUrl = "/images/service/quotes_time-to-throw-away.svg"
                />

                <TimeToThrowServiceHeader/>

                <InquirySection/>
                <FooterOne parentClass=""/>
            </main>

        </>
    )
}

export default TimeToThrowAway;
