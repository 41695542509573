import React from 'react';
import {Link} from "react-router-dom";


const InquirySection = () => {

    return (

        <div className="case-study-page-area kc-pt--90">
            <div className="container">
                <div className="blog-author">
                    <div className="author">
                        <div className="col-xl-10 info">
                            <h5 className="title kc-font-color-white kc-pt--30"><b>지금 바로 문의하세요!</b></h5>
                            <p className="kc-font-color-white">비즈니스 고민만 가져오세요.<br/>
                                코나체인 리서치플랫폼사업팀이 최적의 솔루션을 제작합니다
                            </p>

                        </div>
                        <div className="col-xl-2 kc-footer-inquiry-btn">
                            <Link to={process.env.PUBLIC_URL + "/inquiry"} className="axil-btn btn-fill-white kc-axil-btn kc-font-color-black btn-sm">문의하기</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default InquirySection;
