import React from 'react';


const QuotesArea = ({bgClassName, quotesTitle, quotesImageUrl}) => {

    return (
            <div className={`service-scroll-navigation-area kc-pt--90 pb--90 ${bgClassName}`}>
                <div className="container">
                    <div className="row g-0">
                        <div className="row">
                            <div className="col-lg-7 kc-vertical-center pl--0">
                                <h3 className="kc-title" dangerouslySetInnerHTML={{__html: quotesTitle}}></h3>
                            </div>
                            <div className="col-lg-5  order-lg-1" style={{textAlign: "right"}}>
                                <img src={process.env.PUBLIC_URL + quotesImageUrl} width="100%"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default QuotesArea;
